import { usePageStore } from "../Store"


export default function Pages(){

    const showPage = usePageStore(s=>s.showPage)

    return(
        <div className=' flex-1 overflow-auto p-3 mb-5'>
            {showPage}
        </div>
    )
}