import { TextField, Button} from "@mui/material"
import { useState } from "react"
import { useAuthStore } from "../Store";


export default function Settings(){
    const user = useAuthStore(state => state.user)
    const {changePassword} = useAuthStore()
    const [password, setPassword] = useState()

    return(
        <div className="flex flex-col">
            <header className=" font-semibold border-b w-full text-left mb-2">Ayarlar</header>
            <div className="w-full m-auto">
                <div className=" flex gap-2 ">
                    <div className=" w-1/2 flex flex-col gap-3">
                        <TextField id="namesurname" variant="filled" size="small" fullWidth value={user.data.name} disabled/>
                        <TextField id="password" label="Şifre" variant="filled" type="password" size="small" fullWidth value={password} onChange={e =>setPassword(e.target.value)} />
                    </div>
                    <div className=" w-1/2 flex flex-col gap-3">
                        <TextField id="phone" variant="filled" size="small" fullWidth value={user.data.phone} disabled/>
                        <TextField id="email" variant="filled" size="small" fullWidth value={user.data.mail} disabled/>
                    </div>
                </div>
            
                <div className="w-full mt-2 ">
                    <Button variant="contained" onClick={() => changePassword(password)}>Kayıt Et</Button>
                </div>
            </div>
        </div>
    )
}