import {Footer} from '../'
import { useState } from 'react';
import Login from './Login';
import Resetpassword from './ResetPassword';
import { useAuthStore } from '../../Store';

export default function Auth(){

    const [user, setUser] = useState()
    const [login, setLogin] = useState(true)
    const login_ = useAuthStore(state => state.login)

    return(
        <div className=" flex w-full h-full flex-col justify-between">
            <div className=' flex flex-1 justify-center items-center'>
                <div className=' border border-gray-400/20 rounded shadow-2xl flex flex-col p-6 gap-3 justify-between items-center'>
                    <img draggable="false" src="./src/img/energopro.jpg" alt="Energo-Pro" className='w-52'/>
                    {login && <Login user={user} setUser={setUser} />}
                    {!login && <Resetpassword user={user} use={setUser} />}

                    <div className=' cursor-pointer text-sm text-gray-500 hover:text-gray-600 hover:underline' onClick={() => setLogin(!login)}>
                        {login && "Şifre Sıfırla"}
                        {!login && "Giriş Yap"}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}