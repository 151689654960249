import { useEffect, useState } from "react"
import {GetConnect, PostConnect} from "../../Core/Connect"
import { Table } from "../../Components"
import { Button } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useHeaderTextStore, useModalStore } from "../../Store"

export default function Central(){

	const { t, i18n } = useTranslation()
    const [centrals, setCentrals] = useState([])

	const setHeaderText = useHeaderTextStore(s=>s.setHeaderText)
	const createModal = useModalStore(s=>s.createModal)

	const Delete = d => {
		let postData = {
			id:d
		}
		const callback = d => {
			setCentrals(d.data)
		}

		PostConnect('centrals/post/delete', postData, callback)
	}

    useEffect(() => {
        GetConnect('centrals/get', setCentrals)
    }, [])


	useEffect(()=> {
		setHeaderText(t('ps') + ' / ' + t('powerplant'))
	}, [i18n.language])
    

    return(
        <>
			<div className=" flex justify-end mb-3 mr-5">
				<Button variant="contained" onClick={() => createModal('centralmodal')}>{t('centralmodalbutton')}</Button>
			</div>
            <Table
				searchable={true}
				head={[
					{name: '#ID', sortable: true},
					{name: t('powerplant'), sortable: true},
					{name: t('address')},
                    {name: t('city'), sortable: true},
					{name: t('phornenumber')},
					{name: t('authorized')},
					{name: t('operations'), width: 200},
				]}
				body={centrals.length > 0 && centrals.map((central) => ([
					<div key={central.id}>#{central.id}</div>,
					<div searchableText={`Santral ${central.name}`}>{central.name}</div>,
					central.adress,
                    central.cityname,
					(central.phone ? central.phone : <em>Kayıtlı Telefon Numarası Bulunamadı...</em>),
					(central.user ? central.user : <em>Atanmış Yetkili Bulunmuyor...</em>),
					[
						<button className="h-8 px-4 flex items-center justify-center rounded bg-blue-600 text-white" onClick={() => createModal('centralmodal', {data: central.id, callback: setCentrals})}>{t('edit')}</button>,
						<button onClick={() => Delete(central.id)} className="h-8 px-4 flex items-center justify-center rounded bg-red-600 text-white">{t('delete')}</button>
					]
				]))}
			/>
        </>
    )
}