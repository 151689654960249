import { useEffect } from "react"
import { useReportsStore } from "../Store"
import { Button } from "@mui/material"
import { saveAs } from "file-saver";
//import { useReactToPrint } from 'react-to-print';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import ShowDocs from "./ShowDocs";

export default function CreateReport({data= null}){

    const report = useReportsStore(s=>s.report)
    const { getReport } = useReportsStore()


    //const printRef = useRef()

    useEffect(() => {
        getReport(data.data.id)
    }, [])

    const saveFile = async () => {
        let fileUrl = `https://api.energoproapp.com${report}`
        try {
            const fileName = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
            // Dosyayı Blob olarak al
            const response = await fetch(fileUrl);
            const blob = await response.blob();
      
            // Dosyayı indir
            saveAs(blob, fileName);
          } catch (error) {
            console.error('Dosya indirme hatası:', error);
          }
    }


    /*const handlePrint = useReactToPrint({
        content: () => printRef.current,
      });
    */

    return(
        <>
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                Rapor
            </DialogTitle>
            <DialogContent dividers className="w-full mb-2 flex flex-row gap-2 ">
                {/*<Button variant="contained" onClick={() => generatePDF(printRef, {filename: `${viewTemplate.name}.pdf`})}>PDF Olarak Kayıt Et</Button>*/}
                <Button variant="contained" onClick={saveFile}>Excel Olarak Kaydet</Button>
                {/*<Button variant="contained" onClick={handlePrint}>Yazdır</Button>*/}
            </DialogContent>
            {report && <ShowDocs data={report} />}
        </>
    )
}