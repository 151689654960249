import { useHeaderTextStore } from "../Store/headertext"
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';

export default function Header({drawerWidth, handleDrawerToggle}){

    const headerText = useHeaderTextStore(state => state.headerText)
    /*return(
        <header className=' border-b border-gray-800/5 h-16 text-lg font-semibold flex items-center p-2 bg-gray-300/5'>
            {headerText}
        </header>
    )*/

    let barColor = grey['A100']
    return(
        <AppBar
        position="fixed"
        sx={{
            bgcolor:barColor,
            color:'#000',
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            {headerText}
          </Typography>
        </Toolbar>
      </AppBar>
    )
}