import { useEffect } from "react"
import { Table } from "../../Components"
import Moment from 'moment'
import { useTranslation } from "react-i18next"
import { useAuthStore, useHeaderTextStore, useModalStore, useFaultStore } from "../../Store"
import { ActionButton } from "../../Components/Actionbutton"


export default function FaultList(){

    const { t } = useTranslation()
    const faults = useFaultStore(s=>s.faults)
    const { getFaults, delFaults, completedFault, status,  } = useFaultStore()

    const createModal = useModalStore(s=>s.createModal)
    const setHeaderText = useHeaderTextStore(s=>s.setHeaderText)
    const user = useAuthStore(s=>s.user)

    const Authoritys = user.data.authorities


    useEffect(() => {
        setHeaderText('Arıza Bildirimleri')
        getFaults()
    }, [])


    return(
        <>
            <Table
				searchable={true}
				head={[
                    {name: '#', width: 24},
					{name: t('Arıza Bilgileri'), width: 350, sortable: true},
					{name: 'Arıza Dosyası'},
					{name: t('explanation')},
					{name: t('status')}
				]}
				body={faults && faults.map((fault) => ([
                    [
                        <div className=" flex flex-col">
                            <ActionButton data={{data:{id:fault.id, type:'fault'}}} modal='createnotification' />
                            {fault.status !== 3 && <ActionButton data={{data:fault.id}} modal='completed' fnc={() => completedFault(fault.id)} />}
                        </div>,
                        ((Authoritys.managefault || Authoritys.manageapp) && <div className=" flex flex-col">
                            <ActionButton data={{data:fault.id}} modal='edit' fnc={() => createModal('createfault', fault.id)} />
                            <ActionButton data={{data:fault.id}} modal='delete' fnc={() => delFaults(fault.id)} />
                        </div>)
					],
                    <div searchableText={`${fault.id} - ${fault.centralname} ${fault.unitname}`} className=" flex flex-col whitespace-nowrap text-xs">
                        <span className="border-b border-gray-200 flex justify-between"><strong>{t('addeduser')}:</strong> {fault.username}</span>
                        <span className="border-b border-gray-200 flex justify-between"><strong>{t('powerplant')}:</strong> {fault.centralname}</span>
                        <span className="border-b border-gray-200 flex justify-between"><strong>{t('unit')}:</strong> {fault.unitname}</span>
                        <span className="border-b border-gray-200 flex justify-between"><strong>{t('createdate')}:</strong> {Moment(fault.create_date).format('DD MM YYYY')}</span>
                        <span className="border-b border-gray-200 flex justify-between"><strong>Tamamlanma Tarihi:</strong> {(fault.close_date ? Moment(fault.close_date).format('DD MM YYYY') : '-')}</span>
                    </div>,
                    (fault.file ? <button onClick={() => createModal('showdocs', fault.file)} className="hover:text-gray-600 hover:bg-gray-200 rounded p-2">Dosyayı Görüntüle</button> : <em>Dosya Bulunmuyor</em>),
                    fault.explanation,
                    <div searchableText={status[fault.status]['text']}>{status[fault.status]['element']}</div>
                    
				]))}
			/>
        </>
    )
}