import { Tooltip } from "@mui/material"
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import PendingIcon from '@mui/icons-material/Pending';
import EditIcon from '@mui/icons-material/Edit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import RefreshIcon from '@mui/icons-material/Refresh';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useModalStore } from "../Store";


export const ActionButton = ({data, modal, fnc, ...props}) => {
    const createModal = useModalStore(s=>s.createModal)
    if(typeof(modal) === Object){
        alert('obje')
    }
    
    const icons = {
        'createnotification': {
            text: 'Notlar',
            element: <SpeakerNotesIcon className=" text-gray-600" />,
            function: createModal
        },
        'completed': {
            text: 'Tamamlandı',
            element: <CheckCircleIcon  className=" text-green-600" />,
            function: fnc
        },
        'inprogress': {
            text: 'Devam Ediyor',
            element: <PendingIcon  className=" text-blue-600" />,
            function: fnc
        },
        'incomplete': {
            text: 'İptal Edildi',
            element: <UnpublishedIcon  className=" text-red-700" />,
            function: fnc
        },
        'createmaintenance': {
            text: 'Düzenle',
            element: <EditIcon  className=" text-blue-600" />,
            function: createModal
        },
        'delete': {
            text: 'Sil',
            element: <HighlightOffIcon  className=" text-red-600" />,
            function: fnc
        },
        'edit': {
            text: 'Düzenle',
            element: <EditIcon  className="  text-blue-600" />,
            function: fnc
        },
        'add': {
            text: 'Ekle',
            element: <AddIcon  className="  text-green-600" />,
            function: fnc
        },
        'remove': {
            text: 'Çıkar',
            element: <RemoveIcon  className="  text-red-700" />,
            function: fnc
        },
        'refresh': {
            text: 'Yenile',
            element: <RefreshIcon className=" text-gray-600"/>,
            function: fnc
        },
        'report': {
            text: 'Rapor Oluştur',
            element: <ContentPasteGoIcon  className=" text-gray-600" />,
            function: createModal
        },
    }
    
    return(
        <Tooltip title={icons[modal]['text']} placement="right">
            <span>
            <button onClick={() => icons[modal]['function'](modal, {data:data?.data, page:data?.page})} className=" p-1 border border-gray-300 rounded bg-gray-100 m-0.5 disabled:cursor-not-allowed" {...props}>
                {icons[modal]['element']}
            </button>
            </span>
        </Tooltip>
    )
}
