import React, { useEffect } from 'react'
import { Main, Auth, Loader, Modal } from '../Components';
import { ReactNotifications } from 'react-notifications-component'
import { useLoaderStore, useModalStore, useAuthStore } from '../Store/';
import Update from './Update';

export default function App() {

    const user = useAuthStore(state => state.user)
    const getLogin = useAuthStore(state => state.getLogin)
    const loader = useLoaderStore(state => state.loader)
    const modals = useModalStore(s=>s.modals)
    
    useEffect(() => {
        document.title = 'Energo-Pro Bakım Takip Programı | 2.6.11';
        getLogin()
    
    }, []);


return (
    
    <section className=' w-screen h-screen overflow-hidden select-none'>
        <div className=' flex flex-row w-full h-full max-h-full max-w-full relative'>
            {loader && <Loader />}
            <ReactNotifications />
            
            {user?.data?.token ? 
            <>
                <Main />
                {modals.length > 0 && <Modal />}
            </> 
            : <Auth />}
            
        </div>
        
    </section>
    
    
    )
}
