import i18n from "i18next";
import {initReactI18next} from 'react-i18next';
import ChainedBackend from "i18next-chained-backend";
import HttpBackend from "i18next-http-backend";
import FsBackend from "i18next-fs-backend";

i18n
	.use(ChainedBackend)
	.use(initReactI18next)
	.init({
		fallbackLng: "tr",
		// ... your i18next config
		backend:{
		  backends:[HttpBackend, FsBackend],
		  backendOptions:[{
			loadPath: './locales/{{lng}}/{{ns}}.json'
		  },
		  {
			loadPath: './locales_cache/{{lng}}/{{ns}}.json'
		  }]
		}
	  })

    export default i18n