import { useState } from "react"
import { Table } from "../../Components"
import { Button } from "@mui/material"
import { useModalStore, useInventoryManagementStore } from "../../Store"
import { ActionButton } from "../../Components/Actionbutton"
import StockAction from "./StockAction"
import classNames from "classnames"

export default function WarehouseControl(){

	const createModal = useModalStore(s=>s.createModal)
	const materials = useInventoryManagementStore(s=>s.materials)
	const { deleteMaterial, addStock, removeStock } = useInventoryManagementStore()
	const [stockAction, setStockAction] = useState(false)
  return(
    <div className=" flex flex-1 max-h-full overflow-auto pr-1">
        
        <div className=" flex-1">
            <div className=" flex justify-end mb-3 mr-5 gap-2">
				<Button variant="contained" onClick={() => createModal('createitem')}>Yeni Ürün Ekle</Button>
			</div>
            <div className="overflow-auto">
            <Table
				searchable={true}
				head={[
					{name: '#', width: 40},
					{name: '#ID', sortable: true, width: 40},
					{name: 'Malzeme Detay', sortable: true},
					{name: 'Stok', width: 40},
				]}
				body={materials.length > 0 && materials.map((s) => ([
					[
						<div className=" flex flex-col">
							<ActionButton data={{data:s.id}} modal='add' fnc={() => 
								setStockAction({
									open: true,
									item: s.id,
									type: addStock,
									title: `${s.name} - ${s.material_code} Stok Ekle`,
									text: `${s.name} - ${s.material_code} için eklemek istediğiniz stoğu aşağıda ayrılan alana yazınız.`
								})} />
							<ActionButton data={{data:s.id}} modal='remove' fnc={() => 
								setStockAction({
									open: true,
									item: s.id,
									type: removeStock,
									title: `${s.name} - ${s.material_code} Stoktan çıkar`,
									text: `${s.name} - ${s.material_code} stoktan çıkarmak istediğiniz miktarı aşağıdaki alana yazınız.`
								})} />
						</div>,
						<div className=" flex flex-col">
							<ActionButton data={{data:s.id}} modal='edit' fnc={() => createModal('createitem', s.id)} />
							<ActionButton data={{data:s.id}} modal='delete' fnc={() => deleteMaterial({id:s.id})} />
						</div>
					],
					<div key={s.id}>#{s.id}</div>,
					<div searchableText={`${s.id} - ${s.name} ${s.material_code} ${s.inventoryname}`} className=" flex-1 flex flex-col whitespace-nowrap text-xs">
						<span className="border-b border-gray-300 flex justify-between"><strong>Malzeme Adı:</strong> {s.name}</span>
						<span className="border-b border-gray-300 flex justify-between"><strong>Malzeme Tedarikçi Kodu:</strong> {s.material_code}</span>
						<span className="border-b border-gray-300 flex justify-between"><strong>Malzeme Açıklamaları:</strong> {s.explanation}</span>
						<span className="border-b border-gray-300 flex justify-between"><strong>Malzeme Konumu:</strong> <span className=" font-semibold underline text-gray-900">{s.centralname}/{s.inventoryname}</span></span>
					</div>,
					<div className={classNames(" w-full font-bold text-lg text-center", { 'text-red-600': s.stock && (s.stock < s.critical) })} searchableText={s.stock ? s.stock : '-'}>{s.stock ? s.stock : '-'}</div>,
                    
				]))}
			/>
            </div>
        </div>
		<StockAction action={stockAction} cb={e => setStockAction(e)} />
    </div>
  )  
}