export default function confirm(text){
    return new Promise((resolve, reject) => {
        let retVal = window.confirm(text);
        if( retVal === true ) {
            resolve(true);
        } else {
            reject(false);
        }
    })
    
}