import { useEffect, useState } from "react"
import WarehouseControl from "./WarehouseControl"
import { useInventoryManagementStore, useHeaderTextStore } from "../../Store"
import Home from "./Home"

export default function WarehouseManage(){

    const inventories = useInventoryManagementStore(s=>s.inventories)
    const {getInventories, getMaterials} = useInventoryManagementStore()
    const setHeaderText = useHeaderTextStore(s=>s.setHeaderText)
    const [showMaterials, setShowMaterials] = useState(false)
    

    useEffect(() => {
        getInventories()
        getMaterials()
        setHeaderText('Depo Yönetimi')
    }, [])
    

    return(
        <div className=" flex flex-row gap-1 h-full">
            <div className=" flex flex-col gap-2 pr-2 mr-2 border-r border-gray-300 overflow-auto ">
                <div className=" flex flex-col p-1 bg-gray-300/5 rounded hover:bg-gray-300 cursor-pointer border-b border-gray-300" onClick={() => {setShowMaterials(false) 
                                                                                                                                                            getMaterials(null)}}>
                        <span className=" font-semibold">Home</span><em className=" text-xs">Genel Bilgiler</em></div>
                {inventories.length > 1 && <div className=" flex flex-col p-1 bg-gray-300/5 rounded hover:bg-gray-300 cursor-pointer border-b border-gray-300" onClick={() => {setShowMaterials(true) 
                                                                                                                                                                               getMaterials(null)}}>
                        <span className=" font-semibold">Tümü</span><em className=" text-xs">Tümünü Görüntüle</em></div>}
                {inventories && inventories.map(v => (
                    <div className=" flex flex-col p-1 bg-gray-300/5 rounded hover:bg-gray-300 cursor-pointer border-b border-gray-300" onClick={() => {setShowMaterials(true)
                                                                                                                                                        getMaterials(v.id)}}>
                        <span className=" font-semibold">{v.name}</span><em className=" text-xs">{v.centralname}</em></div>
                ))}
                
            </div>
            {!showMaterials && 
                <>
                    <Home />
                </>
            }
            {showMaterials && 
                <>
                    {inventories.length > 0 && <WarehouseControl />}
                    {inventories.length < 1 && <div className=" border border-red-600 bg-red-600/50 rounded-md p-2 font-semibold text-red-950">Sistemde yönetilebilir bir depo bulunamadı. Eğer bunun bir sorundan kaynaklandığını düşünüyorsanız sistem yöneticisi ile iletişime geçiniz.</div>}
                </>
            }
        </div>
    )
}