import { create } from 'zustand'

export const useLoaderStore = create((set) => ({
    loader: true,
    loaderAction: (data=null) => {
        if(!!data){
            set({loader: data})
        }else{
            set((state) => ({loader: !state.loader}))
        } 
    }
}))