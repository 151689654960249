import { TextField, Button, InputLabel, MenuItem, FormControl } from "@mui/material"
import Select from '@mui/material/Select';
import { useEffect } from "react";
import { useState } from "react";
import { GetConnect, PostConnect } from "../../Core/Connect";
import { useModalStore } from "../../Store";
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

export default function CentrelModal({data= null}){

    const [central, setCentral] = useState('')
    const [adress, setAdress] = useState('')
    const [phone, setPhone] = useState('')
    const [city, setCity] = useState('')
    const [user, setUser] = useState('')

    const [cities, setCities] = useState([])
    const [users, setUsers] = useState([])

    const destroyAllModal = useModalStore(s=>s.destroyAllModal)

    const callback = (d) => {

        if(d.status === true){
            data.callback(d.data)
            destroyAllModal()
            
        }else{
            destroyAllModal()
        }
    }

    const Record = () => {
        let postData = {
          id: (data.data ? data.data : null),
          name:central,
          address:adress,
          phone:phone,
          city:city,
          user:user
        }
        PostConnect(`centrals/post/${(data ? 'update' : 'add')}`, postData, callback)
        
    }

    useEffect(() => {

        if(data.data){

            const DATA = (data) => {
                setCentral(data[0]?.name)
                setAdress(data[0]?.adress)
                setPhone(data[0]?.phone)
                setCity(data[0]?.city)
                setUser(data[0]?.user)
  
            }
    
            GetConnect(`centrals/get/${data.data}`, DATA)
        }

        GetConnect('users/get', setUsers)
        GetConnect('cities/get', setCities)
    },[])



    return(
        <>
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Santral Ekle
            </DialogTitle>
            <div className=" flex">
                <DialogContent dividers className=" w-1/2 flex flex-col gap-3">
                    <TextField id="plantname" label="Santral Adı" variant="filled" size="small" fullWidth value={central} onChange={e =>setCentral(e.target.value)} />
                    <TextField id="address" label="Santral Adresi" variant="filled" size="small" fullWidth value={adress} onChange={e =>setAdress(e.target.value)} />
                </DialogContent>
                <DialogContent dividers className=" w-1/2 flex flex-col gap-3">
                    <TextField id="phone" label="Santral Telefonu" variant="filled" size="small" fullWidth value={phone} onChange={e =>setPhone(e.target.value)} />
                    <div className=" flex gap-2">
                        <FormControl variant="filled" className=" flex-1">
                            <InputLabel id="department-label">Santralin Bulunduğu Şehir</InputLabel>
                            <Select
                            labelId="department-label"
                            id="department-select"
                            value={city}
                            onChange={e => setCity(e.target.value)}
                            size="small"
                            >
                            <MenuItem value="">
                                <em>Seçiniz..</em>
                            </MenuItem>
                            {cities.map(c => (<MenuItem value={c.id} key={c.id}>
                                                    <div className="flex flex-col">
                                                        <div className=" font-semibold">{c.name}</div>
                                                    </div></MenuItem>))}
                            </Select>
                        </FormControl>

                        <FormControl variant="filled" className=" flex-1">
                            <InputLabel id="department-label">Santralin Yetkilisi</InputLabel>
                            <Select
                            labelId="department-label"
                            id="department-select"
                            value={user}
                            onChange={e => setUser(e.target.value)}
                            size="small"
                            >
                            <MenuItem value="">
                                <em>Seçiniz..</em>
                            </MenuItem>
                            {users.map(u => (<MenuItem value={u.id} key={u.id}>
                                                    <div className="flex flex-col">
                                                        <div className=" font-semibold">{u.name}</div>
                                                    </div></MenuItem>))}
                            </Select>
                        </FormControl>
                    </div>
                </DialogContent>
            </div>
            <DialogActions>
                <Button autoFocus onClick={Record}>
                Gönder
                </Button>
            </DialogActions>
        </>
    )
}