import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useEffect, useState } from 'react';
import moment from 'moment';

export default function ChartCalendar({type, cb, currentYear = null}){

    const [select, setSelect] = useState('')

    let quarter = currentYear < moment().year() ? 4 : moment().quarter()
    let month = currentYear < moment().year() ? 11 : moment().month()
    let week = currentYear < moment().year() ? 52 : moment().isoWeek()
    let semester = 2

    const Items = () => {
        let items = [];

        switch (type) {
            case 2:
                for (let i = week; i >= 1; i--) {
                    items.push(
                    <MenuItem key={i} value={i}>
                        Week {i}
                    </MenuItem>
                    );
                }
            break;
            case 3:
                for (let i = month; i >= 0; i--) {
                    items.push(
                    <MenuItem key={i} value={i}>
                        Month {i + 1}
                    </MenuItem>
                    );
                }
            break;
            case 10:
                for (let i = quarter; i >= 1; i--) {
                    items.push(
                    <MenuItem key={i} value={i}>
                        Quarter {i}
                    </MenuItem>
                    );
                }
            break;
            case 6:
                for (let i = semester; i >= 1; i--) {
                    items.push(
                    <MenuItem key={i} value={i}>
                        Semester {i}
                    </MenuItem>
                    );
                }
            break;
            default:
            break;
        }

        return items;
    }

    useEffect(() => {
        switch(type){
            case 2:
                setSelect(week)
                break;
            case 3:
                setSelect(month)
                break;
            case 10:
                setSelect(quarter)
                break;
            case 6:
                setSelect(semester)
                break;
        }
    }, [])

    useEffect(() => {
        cb(select)
    },[select])

    return(
        <Box className=' swiper-no-swiping relative my-2 w-full z-20'>
            <FormControl fullWidth>
                <InputLabel id={`label-${type}`}>Select</InputLabel>
                <Select
                labelId={`label-${type}`}
                id={`select-${type}`}
                value={select}
                label="Select"
                size='small'
                onChange={e => setSelect(e.target.value)}
                >
                {Items()}
                </Select>
            </FormControl>
        </Box>
    )
}