import { Store } from 'react-notifications-component';

export const Notifation = (title, message, type, duration = 1000) => {
    Store.addNotification({
        title,
        message,
        type,
        insert: "bottom",
        container: "bottom-right",
        dismiss: {
          duration,
          onScreen: true
        }
      });
}