import { create } from "zustand";
import { GetConnect,PostConnect } from "../../Core/Connect";

export const useUnitStore = create((set, get) => ({
    units:[],
    setUnit: data => {
        set({units:data})
    },
    getUnit: () => {
        GetConnect('units/get', get().setUnit)
    },
    deleteUnit: data => {
        PostConnect('units/post/delete', data, get().callback)
    },
    callback: data => {
        if(data.status){
            get().getUnit()
        }
    }
}))