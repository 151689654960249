import Test from "./Test";
import ShowDocs from "./ShowDocs";
import CreatStaff from "./CreateStaff";
import AuthorityModal from "./Settings/AuthorityModal"
import CareModal from "./Settings/CareModal"
import CentralModal from "./Settings/CentralModal"
import CityModal from "./Settings/CityModal"
import DepartmentModal from "./Settings/DepartmentModal"
import PeriodModal from "./Settings/PeriodModal"
import UnitModal from "./Settings/UnitModal"
import WarehouseModal from "./Settings/WarehouseModal"
import CreateFault from "./CreateFault"
import CreateMaintenance from "./CreateMaintenance";
import Feedback from "./Feedback";
import AddStock from "./Inventory/AddStock";
import CreateItem from "./Inventory/CreateItem";
import CreateNotification from "./Notification/Create";
import CreateReport from "./CreateReport";


export const modalData = [
    {
        name: 'test',
        element: Test
    },
    {
        name: 'showdocs',
        element: ShowDocs
    },
    {
        name: 'createstaff',
        element: CreatStaff
    },
    {
        name: 'feedback',
        element: Feedback
    },
    {
        name: 'createmaintenance',
        element: CreateMaintenance
    },
    {
        name: 'createfault',
        element: CreateFault
    },
    {
        name: 'authoritymodal',
        element: AuthorityModal
    },
    {
        name: 'caremodal',
        element: CareModal
    },
    {
        name: 'centralmodal',
        element: CentralModal
    },
    {
        name: 'citymodal',
        element: CityModal
    },
    {
        name: 'departmentmodal',
        element: DepartmentModal
    },
    {
        name: 'periodmodal',
        element: PeriodModal
    },
    {
        name: 'unitmodal',
        element: UnitModal
    },
    {
        name: 'warehousemodal',
        element: WarehouseModal
    },
    
    {
        name: 'addstock',
        element: AddStock
    },
    
    {
        name: 'createitem',
        element: CreateItem
    },
    {
        name: 'createnotification',
        element: CreateNotification
    },
    {
        name: 'report',
        element: CreateReport
    }
]