import { TextField, Button, InputLabel, MenuItem, FormControl, Select, InputAdornment } from "@mui/material"
import { useState, useEffect } from "react";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useInventoryManagementStore } from '../../Store';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

export default function CreateItem({data = null}){
    const [name, setName] = useState()
    const [code, setCode] = useState()
    const [explanation, setExplanation] = useState()
    const [inventory, setInventory] = useState('')
    const inventories = useInventoryManagementStore(s=>s.inventories)
    const material = useInventoryManagementStore(s=>s.material)
    const {addMaterial, updateMaterial, getMaterial} = useInventoryManagementStore()

    const [selectedFile, setSelectedFile] = useState({})
    const selectChange = (event) => {
		setSelectedFile(event.target.files[0])
        
	};

    const Record = () => {

        let postData = {
            id: (data ? data : null),
            name: name,
            material_code: code,
            explanation: explanation,
            inventory_id: inventory
        }
        if(data){
            updateMaterial(postData)
        }else{
            addMaterial(postData)
        }
    }

    useEffect(() => {
        if(data){
            getMaterial(data)
        }
    }, [])

    useEffect(() => {
        if(data && material.id){
            setCode(material.material_code)
            setExplanation(material.explanation)
            setInventory(material.inventory_id)
            setName(material.name)
        }
    }, [material])
    return(
        <>
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Yeni Malzeme Ekle
        </DialogTitle>
        <div className=" flex">
            <DialogContent dividers className=" w-1/2 flex flex-col gap-3 flex-1">
                <TextField id="name" label="Ürün Adı" variant="filled" size="small" fullWidth value={name} onChange={e =>setName(e.target.value)} />
                <FormControl variant="filled" fullWidth>
                    <InputLabel id="department-label">Malzemenin bulunduğu envanteri seçiniz</InputLabel>
                    <Select
                    labelId="department-label"
                    id="department-select"
                    value={inventories.length > 0 && inventory}
                    onChange={e => setInventory(e.target.value)}
                    fullWidth
                    >
                    <MenuItem value="">
                        <em>Seçiniz..</em>
                    </MenuItem>
                    {inventories.map(p => (<MenuItem value={p.id} key={p.id}>
                                            <div className="flex flex-col">
                                                <div className=" font-semibold">{p.name}</div>
                                                <em>{p.centralname}</em>
                                            </div></MenuItem>))}
                    </Select>
                </FormControl>
                <TextField id="name" label="Malzemenin Bulunduğu Rafı Yazınız" variant="filled" size="small" fullWidth value={name} onChange={e =>setName(e.target.value)} />
                <TextField id="explanation" multiline rows={4} label="Ürün Açıklaması" variant="filled" size="small" fullWidth value={explanation} onChange={e =>setExplanation(e.target.value)} />
            </DialogContent>

            <DialogContent dividers className=" w-1/2 flex flex-col gap-3 flex-1">
                <TextField 
                    label="Malzeme Görseli" 
                    type="file" 
                    name="file" 
                    id="file"
                    variant="filled" 
                    size="small" 
                    fullWidth 
                    onChange={selectChange} 
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                            <CloudUploadIcon />
                            </InputAdornment>
                        ),
                        }}
                />
                <TextField id="name" label="Tedarikçi Kodu" variant="filled" size="small" fullWidth value={code} onChange={e =>setCode(e.target.value)} />
                <TextField id="name" label="Depo Stok Kodu" variant="filled" size="small" fullWidth value={code} onChange={e =>setCode(e.target.value)} />
                <TextField id="name" label="Kritik Stok Uyarısı" variant="filled" size="small" fullWidth value={code} onChange={e =>setCode(e.target.value)} />

            </DialogContent>


        </div>
        
        <DialogActions>
            <Button autoFocus onClick={Record}>
            Kayıt Et
            </Button>
        </DialogActions>
        </>
    )
}