import { TextField, Button } from "@mui/material"
import { useEffect } from "react";
import { useState } from "react";
import { GetConnect, PostConnect } from "../../Core/Connect";
import { useModalStore } from "../../Store";
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

export default function CityModal({data= null}){

    const [city, setCity] = useState('')

    const destroyAllModal = useModalStore(s=>s.destroyAllModal)

    const callback = (d) => {

        if(d.status === true){
            data.callback(d.data)
            destroyAllModal()
            
        }else{
            alert('Kayıt Tamamlanamadı')
        }
    }

    const Record = () => {
        let postData = {
          id: (data.data ? data.data : null),
          name:city
        }
        PostConnect(`cities/post/${(data ? 'update' : 'add')}`, postData, callback)
        
    }

    useEffect(() => {

        if(data.data){

            const DATA = (data) => {
                setCity(data[0]?.name)
  
            }

            GetConnect(`cities/get/${data.data}`, DATA)
        }
    },[])

    return(
        <>
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Şehir Ekle
            </DialogTitle>
            <DialogContent dividers>
                <TextField id="city" label="Şehir Adı" variant="filled" size="small" fullWidth value={city} onChange={e =>setCity(e.target.value)} />
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={Record}>
                Gönder
                </Button>
            </DialogActions>
        </>
    )
}