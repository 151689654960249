import { create } from "zustand";
import { GetConnect, PostConnect } from '../Core/Connect'
import { Notifation } from '../Utils/Notification'
import { useAuthStore } from "./auth";



export const useNotificationStore = create((set, get) => ({
    notifications: [],
    getdataobje: {},
    getdata: data => {
        GetConnect(`notifications/get/${data.type}/${data.id}`, data => {
            set({notifications: data})
        }, useAuthStore.getState().user.data.token)
    },
    record: data => {
        set({getdataobje:{type: data.work_id ? 'work_id' : 'fault_id', id: data.work_id ? data.work_id : data.fault_id}})
        PostConnect('notifications/post/add', data, get().callback, useAuthStore.getState().user.data.token)
    },
    callback: data => {
        Notifation('Bakım planı işlemi', data.data, data.color, 2000)
        if(data.status){
            get().getdata(get().getdataobje)
        }

    }

}))