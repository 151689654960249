import { useReportsStore } from "../../../Store"
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useEffect } from "react";
import { ActionButton } from "../../../Components/Actionbutton";

export default function CaresList({central = null, array, type, cb}){

    const cares = useReportsStore(s=>s.cares)
    const userCentral = useReportsStore(s=>s.userCentral)

    const status = {
        1: <div className="text-gray-600 font-semibold">Planned</div>,
        2: <div className="text-blue-600 font-semibold">Inprogress</div>,
        3: <div className="text-green-600 font-semibold">Completed</div>,
        4: <div className="text-red-700 font-semibold">Incomplete</div>
    }

    function getStatusAndTargetDateByCare(careValue) {
        for (const veri of array) {
            if (veri.care === careValue) {
                return <div className=" flex flex-row gap-1 justify-between items-center">
                <div className=" flex flex-col gap-1 flex-1">
                    <span className=" border-b border-black/30">{status[veri.status]}</span>
                    <em>{veri.target_date}</em>
                </div>
                {(veri.status === 3 || veri.status === 4) && <ActionButton data={{data:{id:veri.id, type:'maintanence'}}} modal='report' />}
                </div>
            }
        }
    
        return <>-</>; // Eğer verilen care değeri bulunamazsa null döner
    }

    useEffect(() => {
        if(central && cb){
            cb(cares.filter(val => val.central === central)[0]?.cares.filter(item=> item.category === type).length)
        }else{
            cb(cares.reduce((total, val) => {
                return total + val?.cares.filter(item => item.category === type).length;
              }, 0))
        }
        
    }, [central, type])

    return (
        <div className={`shadow-card w-full border border-black/10 mt-5 rounded-xl px-1 py-2 max-h-[35vh] overflow-y-auto`}>
            {(central || userCentral.length === 1) && 
                <div className=" w-full grid grid-cols-5 border border-black/50 p-1 font-medium text-xs rounded">
                    {cares.filter(val => val.central === central)[0]?.cares.filter(item=> item.category === type).map(care => (
                        <>
                            <div className=" col-span-3 border-b border-black/40 border-r p-0.5">{care.name}</div>
                            <div className=" col-span-2 border-b border-black/40 p-0.5 text-center">{getStatusAndTargetDateByCare(care.id)}</div>
                        </>
                    ))}
                </div>
            }
            {(!central && userCentral.length > 1) && <>
                {cares && cares.map((val, key) => (
                    <Accordion key={key}>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id={`panel${key}a-header`}
                        >
                        <Typography>{val.name}</Typography>
                        </AccordionSummary>
                        <div className=" w-full grid grid-cols-5 border border-black/50 p-1 font-medium text-xs rounded">
                            {val?.cares.filter(item => item.category === type).map(care => (
                                <>
                                    <div className=" col-span-3 border-b border-black/40 border-r p-0.5">{care.name}</div>
                                    <div className=" col-span-2 border-b border-black/40 p-0.5 text-center">{getStatusAndTargetDateByCare(care.id)}</div>
                                </>
                                
                            ))}

                            
                        </div>
                    </Accordion>
                ))}
                
            </>}
        </div>
    )
}