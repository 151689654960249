import { TextField, Button, InputLabel, MenuItem, FormControl, Checkbox, ListItemText } from "@mui/material"
import Select from '@mui/material/Select';
import { useEffect, useState } from "react"
import {GetConnect, PostConnect} from "../Core/Connect";
import { useModalStore } from "../Store";
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

export default function CreatStaff({data= null}){

    const [nameSurname, setNameSurname] = useState()
    const [password, setPassword] = useState()
    const [phone, setPhone] = useState()
    const [email, setEmail] = useState()
    const [central, setCental] = useState([])
    const [department, setDepartment] = useState()
    const [authority, setAuthority] = useState()

    //Santaral, Departman, Yetki
    const [centrals, setCentals] = useState([])
    const [departments, setDepartments] = useState([])
    const [authorities, setAuthorities] = useState([])
    const destroyAllModal = useModalStore(s=>s.destroyAllModal)

    


    const handleCentral = (event) => {
        const {
            target: { value },
          } = event;
          setCental(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
          );
    }

    const callback = (d) => {

        if(d.status === true){
            data.callback(d.data)
            destroyAllModal()
            
        }else{
            alert('Personel Eklenemedi!')
        }
    }

    useEffect(() => {
        GetConnect('centrals/get', setCentals)
        GetConnect('departmans/get', setDepartments)
        GetConnect('authorities/get', setAuthorities)

        if(data.data){

            const userData = (data) => {
                setNameSurname(data[0]?.name)
                setPhone(data[0]?.phone)
                setEmail(data[0]?.mail)
                setCental(data[0]?.centrals.map(c => c.central_id))
                setDepartment(data[0]?.department_id)
                setAuthority(data[0]?.authority_id)

            }
    
            GetConnect(`users/get/${data.data}`, userData)
        }
    }, [])


    const singUp = () =>{

        if(!nameSurname){
            document.getElementById("namesurname").focus()
        }
        if(!password && !data.data){
            document.getElementById("password").focus()
        }
        if(!phone){
            document.getElementById("phone").focus()
        }
        if(!email){
            document.getElementById("email").focus()
        }

        if(nameSurname && phone && email){
            let postData = {
                id: (data.data ? data.data : null),
                nameSurname,
                password: (password ? password : null),
                phone,
                email,
                central,
                department,
                authority
            }
            PostConnect(`users/post/${(data.data ? 'updateuser' : 'adduser')}`, postData, callback)
        }else{
            alert('Eksik Bilgi Giriniz.')
        }
    }

    return(
        <>
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Personel Ekle
            </DialogTitle>
            <div className=" flex">
                <DialogContent dividers className=" w-1/2 flex flex-col gap-3">
                    <TextField id="namesurname" label="Ad Soyad" variant="filled" size="small" fullWidth value={nameSurname} onChange={e =>setNameSurname(e.target.value)} />
                    <TextField id="password" label="Şifre" variant="filled" type="password" size="small" fullWidth value={password} onChange={e =>setPassword(e.target.value)} />
                </DialogContent>
                <DialogContent dividers className=" w-1/2 flex flex-col gap-3">
                    <TextField id="phone" label="Telefon Numarası" variant="filled" size="small" fullWidth value={phone} onChange={e =>setPhone(e.target.value)} />
                    <TextField id="email" label="Mail Adresi" variant="filled" size="small" fullWidth value={email} onChange={e =>setEmail(e.target.value)} />
                </DialogContent>
            </div>
                <DialogContent dividers className=" flex gap-2">
                    <FormControl variant="filled" className=" flex-1">
                        <InputLabel id="central-label">Santral</InputLabel>
                        <Select
                        labelId="central-label"
                        id="central-select"
                        multiple
                        value={central}
                        onChange={handleCentral}
                        renderValue={(selected) => selected.join(', ')}
                        sx={{minHeight:72}}
                        >
                        {centrals.map((c) => (
                            <MenuItem key={c.id} value={c.id}>
                                    <Checkbox checked={central.indexOf(c.id) > -1} />
                                    <ListItemText className="flex flex-col">
                                        <div className=" font-semibold">{c.name}</div>
                                        <em className=" text-xs">{c.cityname}</em>
                                    </ListItemText>
                            </MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                    <FormControl variant="filled" className=" flex-1">
                        <InputLabel id="department-label">Departman</InputLabel>
                        <Select
                        labelId="department-label"
                        id="department-select"
                        value={department}
                        onChange={e => setDepartment(e.target.value)}
                        sx={{minHeight:72}}
                        >
                        <MenuItem value="">
                            <em>Seçiniz..</em>
                        </MenuItem>
                        {departments.map(d => (<MenuItem value={d.id} key={d.id}>
                                                <div className="flex flex-col">
                                                    <div className=" font-semibold">{d.name}</div>
                                                </div></MenuItem>))}
                        </Select>
                    </FormControl>
                    <FormControl variant="filled" className=" flex-1">
                        <InputLabel id="demo-simple-select-filled-label">Yetki</InputLabel>
                        <Select
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        value={authority}
                        onChange={e => setAuthority(e.target.value)}
                        sx={{minHeight:72}}
                        >
                        <MenuItem value="">
                            <em>Seçiniz..</em>
                        </MenuItem>
                        {authorities.map(a => (<MenuItem value={a.id} key={a.id}>
                                                <div className="flex flex-col">
                                                    <div className=" font-semibold">{a.name}</div>
                                                </div></MenuItem>))}
                        </Select>
                    </FormControl>
                </DialogContent>
            
            <DialogActions>
                <Button autoFocus onClick={singUp}>
                Gönder
                </Button>
            </DialogActions>
        </>
    )
}