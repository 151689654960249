import { PieChart } from '@mui/x-charts/PieChart';
import { useRef, useState } from 'react';
import CaresList from './CaresList';
import { useContainerDimensions } from '../../../Hooks/useContainerDimensions'
import { Tooltip } from '@mui/material';

 
  const size = {
    height: 150,
  };
  


export default function Chart({text, type, array, central}){

  const componentRef = useRef()
  const { width, height } = useContainerDimensions(componentRef)
  const [careLength, setCareLength] = useState(0)

  //console.log(array)
    

  //week first day
  //moment().week(42)

  //week last day
  //moment().week(42).day(7)

  //console.log(filteredData[filteredData.length-1]?.target_date && moment(filteredData[filteredData.length-1]?.target_date).week())
    let data = [
        { value: array.filter(val => val?.status === 1 && val).length, label: `${Math.floor(array.filter(val => val?.status === 1 && val).length*100/careLength)}% Planlandı`, color:'#4b5563' },
        { value: array.filter(val => val?.status === 3 && val).length, label: `${Math.floor(array.filter(val => val?.status === 3 && val).length*100/careLength)}% Tamamlandı`, color:'#16a34a' },
        { value: array.filter(val => val?.status === 2 && val).length, label: `${Math.floor(array.filter(val => val?.status === 2 && val).length*100/careLength)}% Devam Ediyor`, color:'#2563eb' },
        { value: array.filter(val => val?.status === 4 && val).length, label: `${Math.floor(array.filter(val => val?.status === 4 && val).length*100/careLength)}% İptal Edildi`, color:'#b91c1c' }
    ]


    return(
      <div className=' flex flex-col'>
        <div ref={componentRef} className=' shadow-card w-full flex flex-col items-center justify-center border border-white hover:border-black/20 hover:bg-black/5 rounded-xl relative'>
          <div className=' border-b border-black/20 w-full text-center font-bold text-lg py-2'>{text}</div>
          {array.length > 0 ? <>
            <PieChart  series={[{ data, innerRadius: 30,
              outerRadius: 60,
              paddingAngle: 2,
              cornerRadius: 3,
              startAngle: 0,
              endAngle: Math.floor(360*(data[0].value+data[1].value+data[2].value+data[3].value)/careLength),
              cx: ((width-2)/2)-5,
              highlightScope: {
                highlighted: 'item',
                faded: 'global'
              }
              
            }]} {...size}
              tooltip={{trigger: 'item', classes:{root:['z-50 block']}}}
              slotProps={{
                legend: { hidden: true },
              }}
            />
            <div className=' absolute -z-10 -top-2 font-light text-sm h-full flex items-center justify-center'>
              {`${Math.floor(100*(data[0].value+data[1].value+data[2].value+data[3].value)/careLength)}%`}
            </div>
          </> : <div className=' font-light text-sm h-[150px] flex items-center justify-center'>Veri Bulunamadı</div>}
          <div className=' w-full max-w-full p-2 grid grid-cols-5 justify-between items-center border-t border-black/20'>
            <Tooltip title={`Toplam: ${careLength}`} placement="top">
              <div className='first:border-l-0 last:border-r-0 border-x px-2 border-black/20 flex flex-col justify-center items-center'>
                <p className=' text-xs truncate shrink-0 max-w-full'>Toplam</p>
                <em className=' font-bold text-lg'>{careLength}</em>
              </div>
            </Tooltip>
            {data.map(val => (
              <Tooltip title={`${val.label}: ${val.value}`} placement="top">
                <div className='first:border-l-0 last:border-r-0 border-x px-2 border-black/20 flex flex-col justify-center items-center'>
                  <p className=' text-xs truncate shrink-0 max-w-full'>{val.label}</p>
                  <em className=' font-bold text-lg flex items-center gap-2'>
                    <div style={{'--p-color': val.color}} className=' h-2 w-2 rounded-full bg-[var(--p-color)] -ml-2'></div>
                    {val.value}
                  </em>
                </div>
              </Tooltip>
            ))}
          </div>
        </div>
        <CaresList central={central} array={array} type={type} height={height+65} cb={e=> setCareLength(e)} />
      </div>
    )
}