import { create } from "zustand";
import { GetConnect, PostConnect } from "../../Core/Connect";
import { Notifation } from "../../Utils/Notification";
import { useLoaderStore, useModalStore, useAuthStore } from '../'
import confirm from "../../Utils/confirm";

const loaderAction = useLoaderStore.getState().loaderAction
const destroyAllModal = useModalStore.getState().destroyAllModal

export const useInventorySettingsStore = create((set, get) => ({
    inventories: [],
    inventory: [],
    setInventories: data => {
        loaderAction(false)
        set({inventories:data})
    },
    getInventories: () => {
        loaderAction(true)
        GetConnect('settings/get/inventories', get().setInventories)
    },
    setInventory: data => {
        set({inventory:data[0]})
    },
    getInventory: id => {
        GetConnect('settings/get/inventories/one/'+id, get().setInventory)
    },
    addInventory: data => {
        PostConnect('settings/post/inventories/add', data, get().callback)
    },
    updateInventory: data => {
        PostConnect('settings/post/inventories/update', data, get().callback)
    },
    deleteInventory: data => {

        confirm('Depoyu silmek istediğine eminmisin ?')
        .then(() => {
            Notifation('Envanter Yönetimi', 'Kayıt Başarılı bir şekilde silindi', 'success', 2000)
            PostConnect('settings/post/inventories/delete', data, get().callback)
        })
        .catch(() => {
            Notifation('Envanter Yönetimi', 'Silme işlemi İptal Edildi', 'success', 2000)
        })
        
    },
    callback: data => {
        Notifation('Envanter Yönetimi', data.data, data.color, 2000)
        if(data.status){
            get().getInventories()
            destroyAllModal()
        }
    }
}))

export const useInventoryManagementStore = create((set, get) => ({
    inventories: [],
    materials: [],
    material: [],
    setInventories: data => {
        loaderAction(false)
        set({inventories:data})
    },
    getInventories: () => {
        loaderAction(true)
        GetConnect('inventory/get/user', get().setInventories, useAuthStore.getState().user.data.token)
    },
    setMaterials: data => {
        loaderAction(false)
        set({materials:data})
    },
    getMaterials: (data=null) => {
        loaderAction(true)
        let url = 'inventory/get/inventory'
        if(data){
            url += '/'+data
        }
        GetConnect(url, get().setMaterials, useAuthStore.getState().user.data.token)
    },
    setMaterial: data => {
        loaderAction(false)
        set({material:data})
    },
    getMaterial: data => {
        loaderAction(true)
        GetConnect('inventory/get/material/'+data, get().setMaterial, useAuthStore.getState().user.data.token)
    },
    addMaterial: data => {
        loaderAction(true)
        PostConnect('inventory/post/add', data, get().callback, useAuthStore.getState().user.data.token)
    },
    updateMaterial: data => {
        loaderAction(true)
        PostConnect('inventory/post/update', data, get().callback, useAuthStore.getState().user.data.token)
    },
    addStock: data => {
        loaderAction(true)
        PostConnect('inventory/post/stock/add', data, get().callback, useAuthStore.getState().user.data.token)
    },
    removeStock: data => {
        loaderAction(true)
        PostConnect('inventory/post/stock/remove', data, get().callback, useAuthStore.getState().user.data.token)
    },
    deleteMaterial: data => {
        confirm('Malzemeyi silmek istediğinize eminmisiniz ?')
        .then(() => {
            loaderAction(true)
            Notifation('Envanter Yönetimi', 'Kayıt Başarılı bir şekilde silindi', 'success', 2000)
            PostConnect('inventory/post/delete', data, get().callback, useAuthStore.getState().user.data.token)
        })
        .catch(() => {
            Notifation('Envanter Yönetimi', 'Silme işlemi İptal Edildi', 'success', 2000)
        })
    },
    callback: data => {
        loaderAction(false)
        Notifation('Envanter Yönetimi', data.data, data.color, 2000)
        if(data.status){
            get().getMaterials()
            destroyAllModal()
        }
    }

}))

export const useInventoryStore = create((set, get) => ({
    materials: [],
    getMaterials: () => {
        loaderAction(true)
        GetConnect('inventory/get/all', get().setMaterials, useAuthStore.getState().user.data.token)
    },
    setMaterials: data => {
        loaderAction(false)
        set({materials:data})
    }

}))

