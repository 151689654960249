import axios from "axios"
function GetConnect(url, callback, token = null){
    let headers = {
      'API_KEY': 'NNLYbbVHOS0KR6UR1NpnQH13WsRjnDEf',
      'Content-Type': 'application/json'
    }
    if(token){
      headers['USER_TOKEN'] = token
    }
    axios.get(`https://api.energoproapp.com/v2/${url}`, {
      //https://energoproapp.com
        headers
      })
        .then(function (response) {
          // handle success
          if(response.data.status){
            callback(response.data.data)
          }else{
            callback([])
          }
          
        })
        .catch(function (error) {
          // handle error
          callback(error);
        }) 


}

async function PostConnect(url, data, callback, token = null){
  let headers = {
    'API_KEY': 'NNLYbbVHOS0KR6UR1NpnQH13WsRjnDEf',
    'Content-Type': 'application/json'
  }
  if(token){
    headers['USER_TOKEN'] = token
  }
  await axios.post(`https://api.energoproapp.com/v2/${url}`, {data}, {
    headers
  })
    .then(function(response){
      if(response.data.status){
        callback(response.data)
      }else{
        callback([])
      }
    })
    .catch(function (error) {
      // handle error
      callback(error);
    }) 
}

function PostData(){}

export {
  GetConnect,
  PostConnect,
  PostData
}
