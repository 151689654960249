import { useEffect, useState } from "react"
import {GetConnect, PostConnect} from "../../Core/Connect"
import { Table } from "../../Components"
import { Button } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useHeaderTextStore, useModalStore } from "../../Store"

export default function Department(){


	const { t, i18n } = useTranslation()
    const [departmans, setDepartmans] = useState([])

	const setHeaderText = useHeaderTextStore(s=>s.setHeaderText)
	const createModal = useModalStore(s=>s.createModal)

	const Delete = d => {
		let postData = {
			id:d
		}
		const callback = d => {
			setDepartmans(d.data)
		}

		PostConnect('departmans/post/delete', postData, callback)
	}

    useEffect(() => {
        GetConnect('departmans/get', setDepartmans)
    }, [])

	useEffect(()=> {
		setHeaderText(t('ps') + ' / ' + t('department'))
	}, [i18n.language])

    return(
        <>
			<div className=" flex justify-end mb-3 mr-5">
				<Button variant="contained" onClick={() => createModal('departmentmodal')}>{t('departmentmodalbutton')}</Button>
			</div>
            <Table
				searchable={true}
				head={[
					{name: '#ID', sortable: true},
					{name: t('department'), sortable: true},
					{name: t('operations'), width: 200},
				]}
				body={departmans.length > 0 && departmans.map((departman) => ([
					<div key={departman.id}>#{departman.id}</div>,
					<div searchableText={departman.name}>{departman.name}</div>,
					[
						<button className="h-8 px-4 flex items-center justify-center rounded bg-blue-600 text-white" onClick={() => createModal('departmentmodal', {data: departman.id, callback: setDepartmans})}>{t('edit')}</button>,
						<button onClick={() => Delete(departman.id)} className="h-8 px-4 flex items-center justify-center rounded bg-red-600 text-white">{t('delete')}</button>
					]
				]))}
			/>
        </>
    )
}