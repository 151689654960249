import { useEffect, useState } from "react"
import { Table } from "../../Components"
import {PostConnect} from "../../Core/Connect"
import { useTranslation } from "react-i18next"
import { useAuthStore, useHeaderTextStore, useModalStore, useUserStore } from "../../Store"

export default function ListStaff(){

	const user = useAuthStore(s=>s.user)
	const users = useUserStore(s=>s.users)
	const {getUsers} = useUserStore()

	const { t } = useTranslation()

	const createModal = useModalStore(s=>s.createModal)
	const setHeaderText = useHeaderTextStore(s=>s.setHeaderText)

	const [Users, setUsers] = useState([])

	const Authoritys = user.data.authorities

	const deleteuser = d => {
		let postData = {
			id:d
		}
		const callback = d => {
			setUsers(d.data)
		}

		PostConnect('users/post/deleteuser', postData, callback)
	}

	useEffect(() => {
		setHeaderText('Personel Listesi')
		getUsers()

	}, [])

    return(
        <>
            <Table
				searchable={true}
				head={[
					{name: t('namesurname'), sortable: true},
					{name: t('phornenumber')},
					{name: t('email')},
					{name: t('powerplant')},
					{name: t('department'), sortable: true},
					{name: t('authority'), sortable: true},
					{name: t('operations'), width: 200}
				]}
				body={users && users.map((user) => ([
					<div key={`${user.name}`}>{user.name}</div>,
					(user.phone ? user.phone : <em>Telefon numarası bulunamadı</em>),
					(user.mail ? user.mail : <em>Mail adresi bununamadı</em>),
					(user.centrals && <div className=" grid gap-2">{user.centrals.map((c, k) => (<div key={k} className=" bg-slate-200 rounded-full px-3 py-1 shrink-0">{c.central}</div>))}</div>),
					<div searchableText={`Departman ${user.department}`}>{user.department}</div>,
					<div searchableText={`Yetki ${user.authority}`}>{user.authority}</div>,
					(Authoritys.manageapp) &&
							[
								<button className="h-8 px-4 flex items-center justify-center rounded bg-blue-600 text-white" onClick={() => createModal('createstaff', {data: user.id, callback:setUsers})}>{t('edit')}</button>,
								<button onClick={() => deleteuser(user.id)} className="h-8 px-4 flex items-center justify-center rounded bg-red-600 text-white">{t('delete')}</button>
							]
					
				]))}
			/>
        </>
    )
}