import { TextField, Button, InputLabel, MenuItem, FormControl, InputAdornment } from "@mui/material"
import Select from '@mui/material/Select';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useEffect, useState } from "react";
import { GetConnect } from "../Core/Connect";
import Moment from 'moment'
import { useAuthStore, useFaultStore } from "../Store";
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

export default function CreateFault({data= null}){

    const user = useAuthStore(s=>s.user)

    const fault = useFaultStore(s=>s.fault)
    const {getFault, addFault, updateFault} = useFaultStore()

    const [staffName, setStaffName] = useState(user.data.name)
    const [staffID, setStaffID] = useState(user.data.id)
    const [createDate, setCreateDate] = useState()
    const [central, setCentral] = useState()
    const [unit, setUnit] = useState()
    const [explanation, setExplanation] = useState('')

    const [centrals, setCentrals] = useState([])
    const [units, setUnits] = useState([])

    const [selectedFile, setSelectedFile] = useState({})

    const selectChange = (event) => {
		setSelectedFile(event.target.files[0])
        
	};


    const Record = async () => {

        const formData = new FormData();
		formData.append('file', selectedFile);
        let file = await fetch('https://api.energoproapp.com/upload.php?key=NNLYbbVHOS0KR6UR1NpnQH13WsRjnDEf&folder=Faults',
			{
				method: 'POST',
				body: formData,
			})
            .then((response) => response.json())
            .catch(() => {
                alert('Yükleme Başarısız Oldu')
            });
        
        let postData = {
            id: (data ? data : null),
            file: (file?.link ? file.link : null),
            user_id: staffID,
            central_id: central,
            unit_id: unit,
            create_date: createDate,
            explanation: explanation
        }
                      
        if(data){
            updateFault(postData)
        }else{
            addFault(postData)
        }
          

    }

    useEffect(() => {
        if(data){
            getFault(data)
        }

        setCreateDate(Moment().format('YYYY-MM-DD'))
        GetConnect('centrals/get', setCentrals, user.data.token)
    }, [])

    useEffect(() => {
        if(fault[0]?.id && data){
            setStaffName(fault[0]?.username)
            setStaffID(fault[0]?.user_id)
            setCreateDate(Moment(fault[0]?.create_date).format('YYYY-MM-DD'))
            setCentral(fault[0]?.central_id)
            setUnit(fault[0]?.unit_id)
            setExplanation(fault[0]?.explanation)
        }
    }, [fault])

    useEffect(() => {
        GetConnect('units/get/central/'+central, setUnits)
    }, [central])

   

    return(

        <>
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                Arıza Bildirimi
            </DialogTitle>
            <DialogContent dividers>
                <TextField 
                    label="Arıza Görseli" 
                    type="file" 
                    name="file" 
                    id="file"
                    variant="filled" 
                    size="small" 
                    fullWidth 
                    onChange={selectChange} 
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                            <CloudUploadIcon />
                            </InputAdornment>
                        ),
                        }}
                />
            </DialogContent>
            <div className=" flex">
                <DialogContent dividers className=" w-1/2 flex flex-col gap-3 flex-1">
                    <TextField id="staffname" label="Arıza Bildiren" variant="filled" size="small" fullWidth value={staffName} disabled />
                    <TextField onChange={e => setCreateDate(e.target.value)} id="staffname" label="Arıza Bildiren Tarihi" variant="filled" size="small" type="date" fullWidth value={Moment(createDate).format('YYYY-MM-DD')} disabled/>
                </DialogContent>
                
                <DialogContent dividers className=" w-1/2 flex flex-col gap-3 flex-1">
                    <FormControl variant="filled">
                        <InputLabel id="department-label">Santral</InputLabel>
                        <Select
                        labelId="department-label"
                        id="department-select"
                        value={centrals.length > 0 && central}
                        onChange={e => setCentral(e.target.value)}
                        size="small"
                        >
                        <MenuItem value="">
                            <em>Seçiniz..</em>
                        </MenuItem>
                        {centrals.map(p => (<MenuItem value={p.id} key={p.id}>
                                                <div className="flex flex-col">
                                                    <div className=" font-semibold">{p.name}</div>
                                                </div></MenuItem>))}
                        </Select>
                    </FormControl>
                    <FormControl variant="filled">
                        <InputLabel id="department-label">Birim</InputLabel>
                        <Select
                        labelId="department-label"
                        id="department-select"
                        value={units.length > 0 && unit}
                        onChange={e => setUnit(e.target.value)}
                        size="small"
                        >
                        <MenuItem value="">
                            <em>Seçiniz..</em>
                        </MenuItem>
                        {units.map(p => (<MenuItem value={p.id} key={p.id}>
                                                <div className="flex flex-col">
                                                    <div className=" font-semibold">{p.name}</div>
                                                </div></MenuItem>))}
                        </Select>
                    </FormControl>
                </DialogContent>
            </div>
            <DialogContent dividers>
                <TextField id="explanation" multiline rows={4} label="Açıklama" variant="filled" size="small" fullWidth value={explanation} onChange={e =>setExplanation(e.target.value)} />
            </DialogContent>
            
            <DialogActions>
                <Button autoFocus onClick={Record}>
                Gönder
                </Button>
            </DialogActions>
        </>
            
    )


}