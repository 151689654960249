import { useEffect, useState } from "react"
import {GetConnect, PostConnect} from "../../Core/Connect"
import { Table } from "../../Components"
import { Button } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useHeaderTextStore, useModalStore } from "../../Store"

export default function Period(){


	const { t, i18n } = useTranslation()
    const [periods, setPeriods] = useState([])

	const setHeaderText = useHeaderTextStore(s=>s.setHeaderText)
	const createModal = useModalStore(s=>s.createModal)

	const Delete = d => {
		let postData = {
			id:d
		}
		const callback = d => {
			setPeriods(d.data)
		}

		PostConnect('periods/post/delete', postData, callback)
	}

    useEffect(() => {
        GetConnect('periods/get', setPeriods)
    }, [])

	useEffect(()=> {
		setHeaderText(t('ps') + ' / ' + t('period'))
	}, [i18n.language])

    return(
        <>
			<div className=" flex justify-end mb-3 mr-5">
				<Button variant="contained" onClick={() => createModal('periodmodal')}>{t('periodmodalbutton')}</Button>
			</div>
            <Table
				searchable={true}
				head={[
					{name: '#ID', sortable: true},
					{name: t('period'), sortable: true},
					{name: t('operations'), width: 200},
				]}
				body={periods.length > 0 && periods.map((period) => ([
					<div key={period.id}>#{period.id}</div>,
					<div searchableText={period.name}>{period.name}</div>,
					[
						<button className="h-8 px-4 flex items-center justify-center rounded bg-blue-600 text-white" onClick={() => createModal('periodmodal', {data: period.id, callback: setPeriods})}>{t('edit')}</button>,
						<button onClick={() => Delete(period.id)} className="h-8 px-4 flex items-center justify-center rounded bg-red-600 text-white">{t('delete')}</button>
					]
				]))}
			/>
        </>
    )
}