import { TextField, Button, } from "@mui/material"
import { useState } from "react";
import { PostConnect } from "../Core/Connect";
import { useAuthStore, useModalStore } from "../Store";
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

export default function Feedback(){
    const destroyAllModal = useModalStore(s=>s.destroyAllModal)

    const user = useAuthStore(s=>s.user)
    const [title, setTitle] = useState()
    const [text, setText] = useState()

    const callback = (d) => {

        if(d.status === true){
            destroyAllModal()
            
        }else{
            alert('Kayıt Tamamlanamadı')
        }
    }

    const Record = () => {
        let postData = {
            title,
            text
        }
        PostConnect(`feedback/post/add`, postData, callback, user.data.token)
    }

    return(
        <>
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                Geri Bildirim Gönder
            </DialogTitle>
            <DialogContent dividers>
                <TextField onChange={e => setTitle(e.target.value)} id="title" label="Başlık" variant="filled" size="small" fullWidth value={title} />
            </DialogContent>
            <DialogContent dividers>
                <TextField id="explanation" multiline rows={4} label="Geri Bildirim" variant="filled" size="small" fullWidth value={text} onChange={e =>setText(e.target.value)} />
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={Record}>
                Gönder
                </Button>
            </DialogActions>
        </>
    )
}