import classNames from "classnames"


export default function Card({children, Style = false, ...props}){

    return(

        <div className=" w-full shadow-card p-1 h-fit rounded border border-gray-300/50 ">
            <div className= {classNames("flex justify-between items-center text-xl font-bold px-3 py-5", {
                'text-gray-800': !Style,
                'text-red-600': Style == 'danger',
                'text-green-600': Style == 'success'
            })} {...props}>
                {children}
            </div>
        </div>

    )
}