import { useEffect } from "react"
import { Table } from "../../Components"
import { useHeaderTextStore, useInventoryStore } from "../../Store"

export default function Stock(){

    const setHeaderText = useHeaderTextStore(s=>s.setHeaderText)
	const materials = useInventoryStore(s=>s.materials)
	const {getMaterials} = useInventoryStore()

    useEffect(() => {
		getMaterials()
		setHeaderText('Envanter')

    }, [])

    return(
        <>
            <Table
				searchable={true}
				head={[
					{name: '#ID', sortable: true, width: 40},
					{name: 'Malzeme Detay', sortable: true},
					{name: 'Stok', width: 40},
				]}
				body={materials.length > 0 && materials.map((s) => ([
					<div key={s.id}>#{s.id}</div>,
					<div searchableText={`${s.id} - ${s.name} ${s.material_code} ${s.inventoryname}`} className=" flex-1 flex flex-col whitespace-nowrap text-xs">
						<span className="border-b border-gray-300 flex justify-between"><strong>Malzeme Adı:</strong> {s.name}</span>
						<span className="border-b border-gray-300 flex justify-between"><strong>Malzeme Tedarikçi Kodu:</strong> {s.material_code}</span>
						<span className="border-b border-gray-300 flex justify-between"><strong>Malzeme Açıklamaları:</strong> {s.explanation}</span>
						<span className="border-b border-gray-300 flex justify-between"><strong>Malzeme Konumu:</strong> <span className=" font-semibold underline text-gray-900">{s.centralname}/{s.inventoryname}</span></span>
					</div>,
					<div className=" w-full font-bold text-lg text-center" searchableText={s.stock ? s.stock : '-'}>{s.stock ? s.stock : '-'}</div>,
                    
				]))}
			/>
        </>
    )
}