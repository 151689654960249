import Card from "./Components/Card";
import { Table } from "../../../Components";
import { useInventoryManagementStore } from "../../../Store";

export default function Home(){

    const inventories = useInventoryManagementStore(s=>s.inventories)
    const materials = useInventoryManagementStore(s=>s.materials)

    let critical = 0
    let criticalMaterials = []

    materials.forEach(e => {
        if(e.stock < e.critical){
            critical++
            criticalMaterials.push({...e})
        }
    });

    return(
        <section className=" flex flex-col flex-1 gap-3">
            <div className=" w-full grid grid-cols-3 gap-2">
                <Card>
                    <div>Yönetilen Depo:</div>
                    <span className=" text-4xl pr-3">{inventories.length}</span>
                </Card>
                <Card>
                    <div>Toplam Malzeme:</div>
                    <span className=" text-4xl pr-3">{materials.length}</span>
                </Card>
                <Card Style = 'danger'>
                    <div>Kritik Stok:</div>
                    <span className=" text-4xl pr-3">{critical}</span>
                </Card>
            </div>
            <div className=" w-full grid grid-cols-2 gap-2">
                <Card className=" flex flex-col border border-gray-300 p-2 rounded-sm my-2 gap-1">
                    <h3 className=" font-bold w-full border-b border-gray-300/50">Son İşlemler</h3>
                    <Table
                        searchable={false}
                        head={[
                            {name: 'İşlem'},
                            {name: 'Kullanıcı'},
                            {name: 'Ürün'},
                        ]}
                        body={[]}
                    />
                </Card>
                <Card  className=" flex flex-col border border-gray-300 p-2 rounded-sm my-2 gap-1">
                    <h3 className=" font-bold w-full border-b border-gray-300/50">Kritik Stok</h3>
                    <Table
                        searchable={false}
                        head={[
                            {name: '#ID'},
                            {name: 'Malzeme Detay'},
                            {name: 'Stok'},
                        ]}
                        body={criticalMaterials.length > 0 && criticalMaterials.map((s) => ([
                            <div key={s.id}>#{s.id}</div>,
                            <div className=" flex-1 flex flex-col whitespace-nowrap text-xs">
                                <span className="border-b border-gray-300 flex justify-between"><strong>Malzeme Adı:</strong> {s.name}</span>
                                <span className="border-b border-gray-300 flex justify-between"><strong>Malzeme Tedarikçi Kodu:</strong> {s.material_code}</span>
                                <span className="border-b border-gray-300 flex justify-between"><strong>Malzeme Açıklamaları:</strong> {s.explanation}</span>
                                <span className="border-b border-gray-300 flex justify-between"><strong>Malzeme Konumu:</strong> <span className=" font-semibold underline text-gray-900">{s.centralname}/{s.inventoryname}</span></span>
                            </div>,
                            <div className=" w-full font-bold text-red-600 text-lg text-center">{s.stock ? s.stock : '-'} &lt; {s.critical}</div>,
                            
                        ]))}
                    />
                </Card>
            </div>
        </section>
    )
}