import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'moment/locale/tr';
import moment from "moment";
import { useEffect, useState } from 'react';
const today = moment().local(true);

export default function Calendar({selectYear}){

  const [year, setYear] = useState(moment())

      useEffect(() => {
        selectYear(moment(year).year())
      }, [year])

    return(
        <div className=' transition-all block shadow-card mb-3 py-2 px-1 relative border border-black/10 bg-black/5 rounded overflow-y-auto'>
          <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="tr">
            <div className=' flex flex-row gap-2'>
            <DatePicker 
                views={['year']}
                format="YYYY"  
                label="Yıl Seçin"
                value={year}
                onChange={e => setYear(e)}
                maxDate={today}
                minDate={moment().year(2023).month(0).date(1)}
            />
            </div>
          </LocalizationProvider>
        </div>
    )
}
