import { create } from "zustand";
import { GetConnect,PostConnect } from "../../Core/Connect";

export const useCareStore = create((set, get) => ({
    cares:[],
    setCares: data => {
        set({cares:data})
    },
    getCares: () => {
        GetConnect('cares/get', get().setCares)
    },
    deleteCares: data => {
        PostConnect('cares/post/delete', data, get().callback)
    },
    callback: data => {
        if(data.status){
            get().getCares()
        }
    }
}))