import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App/Core/App';
import './i18n'
import 'react-notifications-component/dist/theme.css'
//import * as serviceWorker from './service-worker';
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);

serviceWorkerRegistration.register();


//serviceWorker.register();

