import { TextField, Button, InputLabel, MenuItem, FormControl, InputAdornment } from "@mui/material"
import Select from '@mui/material/Select';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useState, useEffect } from "react";
import { GetConnect, PostConnect } from "../../Core/Connect";
import { useModalStore, useCareStore } from "../../Store";
import { Notifation } from "../../Utils/Notification";
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';


export default function CareModal({data= null}){

    const [careName, setCareName] = useState('')
    const [period, setPeriod] = useState('')
    const [explanation, setExplanation] = useState('')
    const [unit, setUnit] = useState('')
    const [instructions, setInstructions] = useState('')
    const [controls,setControls] = useState('')

    const [periods, setPeriods] = useState([])
    const [units, setUnits] = useState([])
    const destroyAllModal = useModalStore(s=>s.destroyAllModal)
    const getCares = useCareStore(s=>s.getCares)

    const [selectedFile, setSelectedFile] = useState({})

    const selectChange = (event) => {
		setSelectedFile(event.target.files[0])
        setCareName(event.target.files[0].name)
        
	};

    const createWorsk = () => {
        let splitInstructions = instructions.split('\n')
        let splitControls = controls.split('\n')
        if(splitControls.length === splitInstructions.length){
            let jsonData = []
                splitControls.map((d,i) => {
                jsonData.push({control: d, instructions:splitInstructions[i]})
            })
    
            return JSON.stringify(jsonData)
        }else{
            return false
        }
        
        
    }
    
    const callback = () => {
        getCares()
        destroyAllModal()
    }

    const Record = async () => {

        const formData = new FormData();
		formData.append('file', selectedFile);
        let file = await fetch('https://api.energoproapp.com/upload.php?key=NNLYbbVHOS0KR6UR1NpnQH13WsRjnDEf&folder=Cares',
			{
				method: 'POST',
				body: formData,
			})
            .then((response) => response.json())
            .catch(() => {
                alert('Yükleme Başarısız Oldu')
            });

            if(createWorsk()){
                const postData = {
                    id: (data.data ? data.data : null),
                    file: (file?.link ? file.link : null),
                    name:careName,
                    category: period,
                    explanation: explanation,
                    unit: unit,
                    works: createWorsk()
                }
                PostConnect(`cares/post/${(data ? 'update' : 'add')}`, postData, callback)
            }else{
                Notifation('Kayıt Tamamlanamadı', 'Kontrol ve Talimat satır sayıları eşit olmalıdır. Her satırda bir talimat için bir kontrol tanımlanmalıdır.', 'danger', 3000)
            }
        
    }

    useEffect(() => {
        GetConnect('periods/get', setPeriods)
        GetConnect('units/get', setUnits)

        const DATA = (data) => {
            setCareName(data[0].name)
            setPeriod(data[0].category)
            setExplanation(data[0].explanation)
            setUnit(data[0].unit)

            let Controls = []
            let Intructions = []
            let jsonData = JSON.parse(data[0].works)
            jsonData.map(v => {
                Controls.push(v.control)
                Intructions.push(v.instructions)
            })
            setControls(Controls.join('\n'))
            setInstructions(Intructions.join('\n'))
            
        }
        if(data?.data){
            GetConnect('cares/get/'+data.data, DATA)
        }

    }, [])

    return(
        <>
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Bakım Panı Ekle
            </DialogTitle>
            <div className=" flex">
                <DialogContent dividers className=" w-1/2 flex flex-col gap-2">
                    <TextField id="carename" label="Bakım Başlığı" variant="filled" size="small" fullWidth value={careName} onChange={e =>setCareName(e.target.value)} />
                    <TextField id="explanation" multiline rows={4} label="Bakım Planı Açıklamaları" variant="filled" size="small" fullWidth value={explanation} onChange={e =>setExplanation(e.target.value)} />
                </DialogContent>
                <DialogContent dividers className=" w-1/2 flex flex-col gap-2">
                    <TextField 
                        label="Bakım Plan Dosyası" 
                        type="file" 
                        name="file" 
                        id="file"
                        variant="filled" 
                        size="small" 
                        fullWidth 
                        onChange={selectChange} 
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                <CloudUploadIcon />
                                </InputAdornment>
                            ),
                            }}
                    />

                    <FormControl variant="filled">
                        <InputLabel id="department-label">Periyod</InputLabel>
                        <Select
                        labelId="department-label"
                        id="department-select"
                        value={period}
                        onChange={e => setPeriod(e.target.value)}
                        size="small" 
                        >
                        <MenuItem value="">
                            <em>Seçiniz..</em>
                        </MenuItem>
                        {periods.map(p => (<MenuItem value={p.id} key={p.id}>
                                                <div className="flex flex-col">
                                                    <div className=" font-semibold">{p.name}</div>
                                                </div></MenuItem>))}
                        </Select>
                    </FormControl>

                    <FormControl variant="filled">
                        <InputLabel id="department-label">Unite</InputLabel>
                        <Select
                        labelId="department-label"
                        id="department-select"
                        value={unit}
                        onChange={e => setUnit(e.target.value)}
                        size="small" 
                        >
                        <MenuItem value="">
                            <em>Seçiniz..</em>
                        </MenuItem>
                        {units.map(p => (<MenuItem value={p.id} key={p.id}>
                                                <div className="flex flex-col">
                                                    <div className=" font-semibold"><em>({p.centralname})</em> {p.name}</div>
                                                </div></MenuItem>))}
                        </Select>
                    </FormControl>
                </DialogContent>
            </div>
            
            <DialogContent dividers className=" flex gap-2 flex-col ">
                <TextField id="explanation" multiline rows={4} label="Talimatlar (Her satırda bir talimat olmalı)" variant="filled" size="small" fullWidth value={instructions} onChange={e =>setInstructions(e.target.value)} />
                <span className=" font-light text-xs text-ellipsis">Her satırda bir talimat olmalı</span>
            </DialogContent>
            <DialogContent dividers className=" flex gap-2 flex-col ">
                <TextField id="explanation" multiline rows={4} label="Kontroller (Her Satırda bir kontrol olmalı)" variant="filled" size="small" fullWidth value={controls} onChange={e =>setControls(e.target.value)} />
                <span className=" font-light text-xs text-ellipsis">Her satırda bir kontrol olmalı</span>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={Record}>
                Gönder
                </Button>
            </DialogActions>
        </>
    )
}