import { TextField, Button, InputLabel, MenuItem, FormControl } from "@mui/material"
import Select from '@mui/material/Select';
import { useEffect } from "react";
import { useState } from "react";
import { GetConnect } from "../../Core/Connect";
import { useInventorySettingsStore } from "../../Store";
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

export default function WarehouseModal({data= null}){

    const inventory = useInventorySettingsStore(s=>s.inventory)
    const {getInventory, addInventory, updateInventory} = useInventorySettingsStore()

    const [name, setName] = useState('')
    const [central, setCentral] = useState('')
    const [centrals, setCentrals] = useState('')


    const Record = () => {
        let postData = {
          id:(data.data ? data.data : null),
          name:name,
          central_id:central
        }
        if(data.data){
            updateInventory(postData)
        }else{
            addInventory(postData)
        } 
    }

    useEffect(() => {
        GetConnect('centrals/get', setCentrals)
        if(data.data){
            getInventory(data.data)
        }
        
    }, [])

    useEffect(() => {
        if(inventory?.id && data.data){
            setName(inventory?.name)
            setCentral(inventory?.central_id)
        }
    }, [inventory])

    return(
        <>
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                Depo Ekle
            </DialogTitle>
            <DialogContent dividers>
                <TextField id="unit" label="Depo Adı" variant="filled" size="small" fullWidth value={name} onChange={e =>setName(e.target.value)} />
            </DialogContent>
            <DialogContent dividers>
                <FormControl variant="filled" className=" w-full">
                        <InputLabel id="department-label">Santral</InputLabel>
                        <Select
                        labelId="department-label"
                        id="department-select"
                        value={centrals.length > 0 && central}
                        size="small"
                        onChange={e => setCentral(e.target.value)}
                        >
                        <MenuItem value="">
                            <em>Seçiniz..</em>
                        </MenuItem>
                        {centrals.length > 0 && centrals.map(c => (<MenuItem value={c.id} key={c.id}>
                                                <div className="flex flex-col">
                                                    <div className=" font-semibold">{c.name}</div>
                                                    <div className=" font-light text-sm">{c.cityname}</div>
                                                </div></MenuItem>))}
                        </Select>
                    </FormControl>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={Record}>
                Gönder
                </Button>
            </DialogActions>
        </>
    )
}