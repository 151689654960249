import { TextField, Button, InputLabel, MenuItem, FormControl } from "@mui/material"
import Select from '@mui/material/Select';
import { useEffect, useState } from "react"
import {GetConnect} from "../Core/Connect";
import Moment from 'moment'
import { useAuthStore, useMaintanancesStore } from "../Store";
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

export default function CreateMaintenance({data= null}){

    const user = useAuthStore(state => state.user)

    const [maintenanceTitle, setMaintenanceTitle] = useState()
    const [createDate, setCreateDate] = useState()
    const [plannedDate, setPlannedDate] = useState()
    const [staffName, setStaffName] = useState(user.data.name)
    const [staffID, setStaffID] = useState(user.data.id)
    const [maintenance, setMaintenance] = useState()
    const [userToDo, setUserToDo] = useState()
    const [central, setCentral] = useState()
    const [unit, setUnit] = useState()
    const [explanation, setExplanation] = useState('')


    const [centrals, setCentrals] = useState([])
    const [units, setUnits] = useState([])
    const [staffs, setStaffs] = useState([])
    const [maintenances, setMaintenances] = useState([])

    const { getonadata, maintanance, record } = useMaintanancesStore()

    
    const Record = () => {
        let postData = {
            id: (data.data ? data.data : null),
            "title": maintenanceTitle,
            "added_user": staffID,
            "related_user": userToDo,
            "central": central,
            "unit": unit,
            "care": maintenance,
            "explanation": explanation,
            "create_date": createDate,
            "target_date": plannedDate
        
        }
        record(data.page, postData)
    }


    useEffect(() => {
        setCreateDate(Moment().format('YYYY-MM-DD'))
        !data.data && setPlannedDate(Moment().format('YYYY-MM-DD'))
        GetConnect('centrals/get', setCentrals, user.data.token)
        GetConnect('users/get', setStaffs, user.data.token)
        

        
        if(data.data){
            getonadata(data.data)
        }
        

        
    }, [])

    useEffect(() => {
        if(maintanance[0]?.id && data.data){
            setMaintenanceTitle(maintanance[0]?.title)
            setStaffName(maintanance[0]?.added_username)
            setStaffID(maintanance[0]?.added_user)
            setUserToDo(maintanance[0]?.related_user)
            setCentral(maintanance[0]?.central)
            setUnit(maintanance[0]?.unit)
            setCreateDate(maintanance[0]?.create_date)
            setPlannedDate(maintanance[0]?.target_date)
            setExplanation(maintanance[0]?.explanation)
            setMaintenance(maintanance[0]?.care)
        }
        
    }, [maintanance])

    useEffect(() => {
        GetConnect('units/get/central/'+central, setUnits)
    }, [central])

    useEffect(() => {
        GetConnect('cares/get/unit/'+unit, setMaintenances)
    }, [unit])




    return(
        <>
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                Bakım Planı
            </DialogTitle>
            <DialogContent dividers>
                <TextField id="maintenancetitle" label="Bakım Başlığı" variant="filled" size="small" fullWidth value={maintenanceTitle} onChange={e =>setMaintenanceTitle(e.target.value)} />
            </DialogContent>
            <div className=" flex">
                <DialogContent dividers className=" w-1/2 flex flex-col gap-3 shrink-0">
                    <TextField onChange={e => setCreateDate(e.target.value)} id="staffname" label="Oluşturma Tarihi" variant="filled" size="small" type="date" fullWidth value={Moment(createDate).format('YYYY-MM-DD')} disabled/>
                    <TextField onChange={e => setPlannedDate(e.target.value)} id="staffname" label="Planlanan Tarih" variant="filled" size="small" type="date" fullWidth value={Moment(plannedDate).format('YYYY-MM-DD')}/>

                    <TextField id="created" label="Oluşturan" variant="filled" size="small" fullWidth value={staffName} disabled />
                    <FormControl variant="filled">
                            <InputLabel id="department-label">İlgilenecek Personel</InputLabel>
                            <Select
                            labelId="department-label"
                            id="department-select"
                            value={staffs.length > 0 && userToDo}
                            onChange={e => setUserToDo(e.target.value)}
                            size="small"
                            >
                            <MenuItem value="">
                                <em>Seçiniz..</em>
                            </MenuItem>
                            {staffs.map(d => (<MenuItem value={d.id} key={d.id}>
                                                    <div className="flex flex-col">
                                                        <div className=" font-semibold">{d.name}</div>
                                                        <em>{d.central}</em>
                                                    </div></MenuItem>))}
                            </Select>
                        </FormControl>
                </DialogContent>
                <DialogContent dividers className=" w-1/2 flex flex-col gap-3 shrink-0">
                    <FormControl variant="filled">
                        <InputLabel id="department-label">Santral</InputLabel>
                        <Select
                        labelId="department-label"
                        id="department-select"
                        value={centrals.length > 0 && central}
                        onChange={e => setCentral(e.target.value)}
                        size="small"
                        >
                        <MenuItem value="">
                            <em>Seçiniz..</em>
                        </MenuItem>
                        {centrals.map(p => (<MenuItem value={p.id} key={p.id}>
                                                <div className="flex flex-col">
                                                    <div className=" font-semibold">{p.name}</div>
                                                </div></MenuItem>))}
                        </Select>
                    </FormControl>
                    <FormControl variant="filled">
                        <InputLabel id="department-label">Birim</InputLabel>
                        <Select
                        labelId="department-label"
                        id="department-select"
                        value={units.length > 0 && unit}
                        onChange={e => setUnit(e.target.value)}
                        size="small"
                        >
                        <MenuItem value="">
                            <em>Seçiniz..</em>
                        </MenuItem>
                        {units.map(p => (<MenuItem value={p.id} key={p.id}>
                                                <div className="flex flex-col">
                                                    <div className=" font-semibold">{p.name}</div>
                                                    
                                                </div></MenuItem>))}
                        </Select>
                    </FormControl>
                    <FormControl variant="filled">
                        <InputLabel id="department-label">Bakım Planı</InputLabel>
                        <Select
                        labelId="department-label"
                        id="department-select"
                        value={maintenances.length > 0 && maintenance}
                        onChange={e => setMaintenance(e.target.value)}
                        size="small"
                        >
                        <MenuItem value="">
                            <em>Seçiniz..</em>
                        </MenuItem>
                        {maintenances.map(p => (<MenuItem value={p.id} key={p.id}>
                                                <div className="flex flex-col">
                                                    <div className=" font-semibold">{p.name}</div>
                                                </div></MenuItem>))}
                        </Select>
                    </FormControl>
                </DialogContent>
            </div>
            <DialogContent dividers>
                <TextField id="explanation" multiline rows={4} label="Açıklama" variant="filled" size="small" fullWidth value={explanation} onChange={e =>setExplanation(e.target.value)} />
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={Record}>
                Kayıt Et
                </Button>
            </DialogActions>
        </>
    )

}