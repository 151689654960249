import { useEffect } from "react"
import { Table } from "../../Components"
import { Button } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useHeaderTextStore, useModalStore, useCareStore } from "../../Store"

export default function Care(){

	const { t, i18n } = useTranslation()
	const cares = useCareStore(s=>s.cares)
	const {deleteCares, getCares} = useCareStore()

	const setHeaderText = useHeaderTextStore(s=>s.setHeaderText)
	const createModal = useModalStore(s=>s.createModal)

    const Delete = d => {
		let postData = {
			id:d
		}
		deleteCares(postData)
	}

	useEffect(() => {getCares()}, [])


	useEffect(()=> {
		setHeaderText(t('ps') + ' / ' + t('care'))
	}, [i18n.language])


    return(
        <>

			<div className=" flex justify-end mb-3 mr-5">
				<Button variant="contained" onClick={() => createModal('caremodal')}>{t('caremodalbutton')}</Button>
			</div>
            <Table
				searchable={true}
				head={[
					{name: '#ID', sortable: true},
					{name: t('care'), sortable: true},
					{name: t('file')},
					{name: t('unit')},
					{name: t('period')},
					{name: t('explanations')},
					{name: t('operations'), width: 200}
				]}
				body={cares.length > 0 && cares.map((Care) => ([
					<div key={Care.id}>#{Care.id}</div>,
					<div searchableText={`Bakım ${Care.name}`}>{Care.name}</div>,
					<button onClick={() => createModal('showdocs', Care.file)} className="hover:text-gray-600 hover:bg-gray-200 rounded p-2">Dosya</button>,
					<div searchableText={Care.unitname}>{Care.unitname}</div>,
					<div searchableText={Care.periodname}>{Care.periodname}</div>,
					(Care.explanation ? Care.explanation : <em>Açıklama Eklenmedi...</em>),
					[
						<button className="h-8 px-4 flex items-center justify-center rounded bg-blue-600 text-white" onClick={() => createModal('caremodal', {data: Care.id})}>{t('edit')}</button>,
						<button onClick={() => Delete(Care.id)} className="h-8 px-4 flex items-center justify-center rounded bg-red-600 text-white">{t('delete')}</button>
					]
				]))}
			/>
        </>
    )
}