import { useEffect } from "react"
import { Table } from "../../Components"
import { Button } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useHeaderTextStore, useModalStore, useUnitStore } from "../../Store"

export default function Unit(){

	const { t, i18n } = useTranslation()
    const units = useUnitStore(s=>s.units)
	const {deleteUnit, getUnit} = useUnitStore()

	const setHeaderText = useHeaderTextStore(s=>s.setHeaderText)
	const createModal = useModalStore(s=>s.createModal)

	const Delete = d => {
		let postData = {
			id:d
		}
		deleteUnit(postData)
	}

    useEffect(() => {getUnit()}, [])

	useEffect(()=> {
		setHeaderText(t('ps') + ' / ' + t('unit'))
	}, [i18n.language])

    return(
        <>
			<div className=" flex justify-end mb-3 mr-5">
				<Button variant="contained" onClick={() => createModal('unitmodal')}>{t('unitmodalbutton')}</Button>
			</div>
            <Table
				searchable={true}
				head={[
					{name: '#ID', sortable: true},
					{name: t('city'), sortable: true},
					{name: t('powerplant'), sortable: true},
					{name: t('operations'), width: 200},
				]}
				body={units.length > 0 && units.map((unit) => ([
					<div key={unit.id}>#{unit.id}</div>,
					<div searchableText={`Birim ${unit.name}`}>{unit.name}</div>,
                    unit.centralname,
					[
						<button className="h-8 px-4 flex items-center justify-center rounded bg-blue-600 text-white" onClick={() => createModal('unitmodal', {data: unit.id})}>{t('edit')}</button>,
						<button onClick={() => Delete(unit.id)} className="h-8 px-4 flex items-center justify-center rounded bg-red-600 text-white">{t('delete')}</button>
					]
				]))}
			/>
        </>
    )
}