import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useState, useEffect } from "react";
import { GetConnect, PostConnect } from "../../Core/Connect";
import { useModalStore } from '../../Store';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

const Authorities = {
    'newmaintanence':'Yeni Bakım Planı Ekleye Bilir.',
    'managemaintanence':'Bakım Planlarını Yönetebilir.',
    'managefault':'Arızaları Yönetebilir',
    'managestaff':'Personelleri Yönetebilir',
    'managewarehouse':'Depoyu yönetebilir',
    'allmanagewarehose':'Tüm Depoları Yönetebilir',
    'managemsi':'Yönetim Sistemi ve Talimatları Yönetebilir',
    'manageapp':'Program Ayarlarını Yönetebilir'
}

export default function AuthorityModal({data= null}){

    const theme = useTheme();
    const [authorityName, setAuthorityName] = useState('')
    const [authority, setAuthority] = useState([])


    const destroyAllModal = useModalStore(s=>s.destroyAllModal)

    const handleChange = (event) => {
        const {
          target: { value },
        } = event;
        setAuthority(
          // On autofill we get a stringified value.
          typeof value === 'string' ? value.split(',') : value,
        );
      };
    
      const callback = (d) => {

        
        if(d.status === true){
            data.callback(d.data)
            destroyAllModal()
            
        }else{
            alert('Kayıt Tamamlanamadı')
        }
    }

    const Record = () => {
        let postData = {
          id: (data.data ? data.data : null),
          name: authorityName,
          authorities: authority
        }
        PostConnect(`authorities/post/${(data ? 'update' : 'add')}`, postData, callback)
        
    }

    useEffect(() => {
      if(data.data){

          const DATA = (data) => {
              setAuthorityName(data[0]?.name)
              setAuthority(Object.keys(data[0]?.authorities).map(e => e))

          }
  
          GetConnect(`authorities/get/${data.data}`, DATA)
      }
  }, [])


    return(
          <>
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Yetki Ekle
            </DialogTitle>
            <DialogContent dividers>
              <TextField id="city" label="Yetki Başlığı" variant="filled" size="small" fullWidth value={authorityName} onChange={e =>setAuthorityName(e.target.value)} />
            </DialogContent>
            <DialogContent dividers>
            <FormControl sx={{ minWidth: 400, marginTop: 2}} fullWidth>
                    <InputLabel id="demo-multiple-chip-label">Yetki Türleri</InputLabel>
                    <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    size='small'
                    value={authority}
                    onChange={handleChange}
                    input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                            <Chip key={value} label={value} />
                        ))}
                        </Box>
                    )}
                    MenuProps={MenuProps}
                    >
                        {Object.entries(Authorities).map(([key, val]) => (
                            <MenuItem
                                key={key}
                                value={key}
                                style={getStyles(val, authority, theme)}
                                >
                                {val}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={Record}>
                Gönder
                </Button>
            </DialogActions>
        </>
    )
}
