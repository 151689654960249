import { useEffect, useState } from "react"
import {GetConnect, PostConnect} from "../../Core/Connect"
import { Table } from "../../Components"
import { Button } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useHeaderTextStore, useModalStore } from "../../Store"

export default function Authority(){

	const { t, i18n } = useTranslation()
    const [authorities, setAuthorities] = useState([])

	const setHeaderText = useHeaderTextStore(s=>s.setHeaderText)
	const createModal = useModalStore(s=>s.createModal)

	const Delete = d => {
		let postData = {
			id:d
		}
		const callback = d => {
			setAuthorities(d.data)
		}

		PostConnect('authorities/post/delete', postData, callback)
	}

    useEffect(() => {
        GetConnect('authorities/get', setAuthorities)
    }, [])


	useEffect(()=> {
		setHeaderText(t('ps') + ' / ' + t('authority'))
	}, [i18n.language])

    return(
        <>
			<div className=" flex justify-end mb-3 mr-5">
				<Button variant="contained" onClick={() => createModal('authoritymodal')}>{t('authortymodalbutton')}</Button>
			</div>
            <Table
				searchable={true}
				head={[
					{name: '#ID', sortable: true},
					{name: t('authority'), sortable: true},
					{name: t('authorities')},
					{name: t('operations'), width: 200},
				]}
				body={authorities.length > 0 && authorities.map((authority) => ([
					<div key={authority.id}>#{authority.id}</div>,
					<div searchableText={authority.name}>{authority.name}</div>,
					(authority.authorities && Object.keys(authority.authorities).map(a => (<div className=" bg-slate-200 rounded-full px-3 py-1">{a}</div>))),
					[
						<button className="h-8 px-4 flex items-center justify-center rounded bg-blue-600 text-white" onClick={() => createModal('authoritymodal', {data: authority.id, callback: setAuthorities})}>{t('edit')}</button>,
						<button onClick={() => Delete(authority.id)} className="h-8 px-4 flex items-center justify-center rounded bg-red-600 text-white">{t('delete')}</button>
					]
				]))}
			/>
        </>
    )
}