import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useState } from 'react';
import { useAuthStore } from '../../../Store';
export default function Login({user, setUser}){
    const [password, setPassword] = useState()
    const login = useAuthStore(state => state.login)

    const changePhone = (e) => {
        let text = e.target.value.replace(" ","");
        text = text.replace(/[^0-9.+]/g,'');
        setUser(text.trim())
    }

    const keyDownHandler = event => {
        if (event.key === 'Enter') {
        event.preventDefault();
        // 👇️ your logic here
        Login()
        }
    };

    const Login = () => {
        login({
            user,
            password
        })    
    }

    return(
        <>
            <TextField 
                id="user" 
                label="Kullanıcı Telefon Numarası" 
                variant="filled" 
                size="small" 
                fullWidth 
                value={user} 
                onChange={changePhone} 
                onKeyDown={keyDownHandler}/>
            <TextField 
                id="password" 
                type="password" 
                label="Şifrenizi Giriniz" 
                variant="filled" 
                size="small" 
                fullWidth 
                value={password} 
                onChange={e =>setPassword(e.target.value)} 
                onKeyDown={keyDownHandler}/>
            <div className='flex-1 my-5'>
                <Button variant="contained" onClick={() => Login()} >Giriş Yap</Button>
            </div>
        </>
    )
}