import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function StockAction({action, cb}){
    const [open, setOpen] = useState(false);
    const [stock, setStock] = useState('')
    const [explanation, setExplanation] = useState('')
  
    const handleClose = () => {
      setOpen(false);
      setStock('')
      setExplanation('')
      cb(false)
    };

    const confirm = () => {
        let data = {
            material_id: action.item,
            explanation: explanation,
            stock: stock
        }
        action.type(data)
        handleClose()
    }

    useEffect(() => {
        if(action?.open === true){
            setOpen(true)
        }
    }, [action])
  
    return (
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>{action.title}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {action.text}
            </DialogContentText>
            <TextField
              autoFocus
              value={stock}
              onChange={e => setStock(e.target.value)}
              margin="dense"
              id="name"
              label="Adet"
              type="number"
              fullWidth
              variant="standard"
            />
          </DialogContent>
          <DialogContent>
            <DialogContentText>
              Lütfen Bir Açıklama Giriniz.
            </DialogContentText>
            <TextField
              value={explanation}
              onChange={e => setExplanation(e.target.value)}
              margin="dense"
              id="explanation"
              multiline rows={4} label="Açıklama" variant="filled" size="small" fullWidth
              required
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>İptal Et</Button>
            <Button onClick={confirm}>Kayıt Et</Button>
          </DialogActions>
        </Dialog>
    );
}