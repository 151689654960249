import {modalData} from '../Modals'
import { useModalStore } from "../Store";
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

export default function Modal(){
    const modals = useModalStore(s => s.modals)
    const destroyModal = useModalStore(s=> s.destroyModal)


    /*return(
        <div className=" fixed inset-0 bg-black/50 flex items-center justify-center z-30 ">
            <div className=" bg-white relative p-4 rounded-sm max-h-[90%] overflow-auto max-w-[60%]">
            <button className=" absolute top-1 right-1 rounded p-1 flex items-center bg-black/10  text-gray-600 hover:text-gray-800 transition-colors"  onClick={destroyModal}> <CloseIcon fontSize="small" /> </button>
                    {modals.map(modal => {
                    
                        const showModal = modalData.find(m => m.name === modal.name)
                        return <div className="overflow-auto hidden last:block ">
                                <showModal.element data={modal.data} />
                            </div>
                    })}
            </div>
                
        </div>
    )*/

    return(
        <BootstrapDialog
            onClose={destroyModal}
            aria-labelledby="customized-dialog-title"
            open={modals.length > 0}
            fullWidth
            maxWidth={'lg'}
        >
        
        <IconButton
          aria-label="close"
          onClick={destroyModal}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        {modals.map((modal, key) => {
            const showModal = modalData.find(m => m.name === modal.name)
            return <div key={key} className="overflow-auto hidden last:block ">
                    <showModal.element data={modal.data} />
                </div>
        })}
      </BootstrapDialog>
    )
}