import {Authority, Care, Central, City, Department, Period, Unit, Warehouse} from './'
import { useTranslation } from "react-i18next";
import { useEffect, useState } from 'react'
import { useHeaderTextStore } from '../Store';

export default function Settings(){
    const { t } = useTranslation()

    const setHeaderText = useHeaderTextStore(s=>s.setHeaderText)

    const [setting, setSetting] = useState('care')
    const [showSetting, setShowSetting] = useState(<Care />)


    useEffect(() => {
        switch(setting){
            case 'care':
                setShowSetting(<Care />)
                break;
            case 'central':
                setShowSetting(<Central />)
                break;
            case 'department':
                setShowSetting(<Department />)
                break;
            case 'city':
                setShowSetting(<City />)
                break;
            case 'unit':
                setShowSetting(<Unit />)
                break;
            case 'period':
                setShowSetting(<Period />)
                break;
            case 'warehouse':
                setShowSetting(<Warehouse />)
                break;
            case 'authority':
                setShowSetting(<Authority />)
                break;
            default:
                setShowSetting(<>{t('psnot')}</>)
                setHeaderText(t('ps'))

        }
    }, [setting])

    return(
        <div className=' flex flex-col max-h-full'>
            <div className=' h-11 border-b border-gray-400'>
                <ul className=' flex items-center overflow-x-auto  space-x-1 pb-1 z-10'>
                    <li className={` shrink-0 transition-all border-b-2 px-3 py-1 rounded-sm ${setting === 'care' && 'border-gray-600 bg-gray-100'} hover:border-gray-600 cursor-pointer hover:bg-gray-100 `} onClick={() => setSetting('care')}>{t('pstab1')}</li>
                    <li className={` shrink-0 transition-all border-b-2 px-3 py-1 rounded-sm ${setting === 'central' && 'border-gray-600 bg-gray-100'} hover:border-gray-600 cursor-pointer hover:bg-gray-100 `} onClick={() => setSetting('central')}>{t('pstab2')}</li>
                    <li className={` shrink-0 transition-all border-b-2 px-3 py-1 rounded-sm ${setting === 'warehouse' && 'border-gray-600 bg-gray-100'} hover:border-gray-600 cursor-pointer hover:bg-gray-100 `} onClick={() => setSetting('warehouse')}>{t('pstab3')}</li>
                    <li className={` shrink-0 transition-all border-b-2 px-3 py-1 rounded-sm ${setting === 'department' && 'border-gray-600 bg-gray-100'} hover:border-gray-600 cursor-pointer hover:bg-gray-100 `} onClick={() => setSetting('department')}>{t('pstab4')}</li>
                    <li className={` shrink-0 transition-all border-b-2 px-3 py-1 rounded-sm ${setting === 'city' && 'border-gray-600 bg-gray-100'} hover:border-gray-600 cursor-pointer hover:bg-gray-100 `} onClick={() => setSetting('city')}>{t('pstab5')}</li>
                    <li className={` shrink-0 transition-all border-b-2 px-3 py-1 rounded-sm ${setting === 'unit' && 'border-gray-600 bg-gray-100'} hover:border-gray-600 cursor-pointer hover:bg-gray-100 `} onClick={() => setSetting('unit')}>{t('pstab6')}</li>
                    <li className={` shrink-0 transition-all border-b-2 px-3 py-1 rounded-sm ${setting === 'period' && 'border-gray-600 bg-gray-100'} hover:border-gray-600 cursor-pointer hover:bg-gray-100 `} onClick={() => setSetting('period')}>{t('pstab7')}</li>
                    <li className={` shrink-0 transition-all border-b-2 px-3 py-1 rounded-sm ${setting === 'authority' && 'border-gray-600 bg-gray-100'} hover:border-gray-600 cursor-pointer hover:bg-gray-100 `} onClick={() => setSetting('authority')}>{t('pstab8')}</li>
                </ul>
            </div>

            <div className=' flex-1 overflow-y-auto p-2'>
                {showSetting}
            </div>
        </div>
    )
}