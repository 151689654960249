import { useEffect } from "react"
import { Table } from "../../Components"
import { Button } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useHeaderTextStore, useModalStore, useInventorySettingsStore } from "../../Store"
import { ActionButton } from "../../Components/Actionbutton"

export default function Warehouse(){

    const { t, i18n } = useTranslation()
	const inventories = useInventorySettingsStore(s=>s.inventories)
	const {getInventories, deleteInventory} = useInventorySettingsStore()
	const setHeaderText = useHeaderTextStore(s=>s.setHeaderText)
	const createModal = useModalStore(s=>s.createModal)

    useEffect(() => {
        getInventories()
    }, [])


    useEffect(()=> {
		setHeaderText(t('ps') + ' / ' + t('warehouse'))
	}, [i18n.language])

    return(
        <>
            <div className=" flex justify-end mb-3 mr-5">
				<Button variant="contained" onClick={() => createModal('warehousemodal')}>{t('warehousemodalbutton')}</Button>
			</div>
            <Table
				searchable={true}
				head={[
					{name: '#', width: 40},
					{name: '#ID', sortable: true},
					{name: t('warehouse'), sortable: true},
					{name: t('powerplant'), sortable: true}
				]}
				body={inventories.length > 0 && inventories.map((inventory) => ([
					[
						<div className=" flex flex-col">
							<ActionButton data={{data:inventory.id}} modal='edit' fnc={() => createModal('warehousemodal', {data: inventory.id})} />
							<ActionButton data={{data:inventory.id}} modal='delete' fnc={() => deleteInventory({id:inventory.id})} />
						</div>
					],
					<div key={inventory.id}>#{inventory.id}</div>,
					<div searchableText={`Birim ${inventory.name}`}>{inventory.name}</div>,
                    inventory.centralname
					
				]))}
			/>
        </>
    )
}