import { create } from 'zustand'
import { PostConnect, GetConnect } from '../Core/Connect'
import { useLoaderStore } from './loader'
import { Notifation } from '../Utils/Notification'

const loaderAction = useLoaderStore.getState().loaderAction

export const useAuthStore = create((set, get) => ({
	user: null,
	login: loginData => {
		loaderAction(true)
		PostConnect('login', loginData, get().setLogin)
	},
	setLogin: data => {
		if(data.data){
			localStorage.setItem('Auth', JSON.stringify(data))
			set({user: data})
			Notifation('Giriş Başarılı', `Hoşgeldiniz, ${data.data.name}`, 'success')
				
		}else{
			Notifation('Giriş Yapılamadı', 'Kullanıcı adı veya şifre hatalı, lütfen bilgileri kontrol ederek tekrar deneyiniz.', 'danger', 2000)
		}	
		loaderAction(false)
	},
	getLogin: () => {
		if(localStorage.getItem('Auth')){
			let User = JSON.parse(localStorage.getItem('Auth'))
			const Control = data => {
				if(data === 'loginFailed'){
					localStorage.removeItem('Auth')
					set({user: {}})
					Notifation('Hatalı Kullanıcı Bilgisi', 'Görünüşe göre birşeyler ters gitti. Lütfen tekrar giriş yapınız.', 'danger', 2000)
				}else{
					set({user: User})
				}
			}
			GetConnect('', Control, User?.data?.token)
		}
		loaderAction(false)
	},
	changePassword: data => {
		if(data.length > 9){
			PostConnect('users/post/changepassword', {password:data}, get().callback, get().user.data.token)
		}else{
			Notifation('Şifre Uygum Değil', 'Yeni şifre en az 10 karakterden oluşmalıdır. ', 'info',2000)
		}
	},
	logout: () => {
		set({user: {}})
		localStorage.removeItem('Auth')
	},
	callback: data => {
		if(data.status){
			Notifation('Şifre değişti', 'Şifreniz başarılı bir şekilde değişti. Lütfen tekrar giriş yapınız.', 'success',2000)
			get().logout()
		}else{
			Notifation('Şifre Değiştirilemedi', 'Şifreniz değiştirilirken bir hata meydana geldi. Lütfendaha sonra tekrar deneyiniz. ', 'danger',2000)
		}
	}

}))