import { useEffect, useState } from "react"
import {GetConnect, PostConnect} from "../../Core/Connect"
import { Table } from "../../Components"
import { Button } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useHeaderTextStore, useModalStore } from "../../Store"

export default function City(){

	const { t, i18n } = useTranslation()
    const [cities, setCities] = useState([])

	const setHeaderText = useHeaderTextStore(s=>s.setHeaderText)
	const createModal = useModalStore(s=>s.createModal)

	const Delete = d => {
		let postData = {
			id:d
		}
		const callback = d => {
			setCities(d.data)
		}

		PostConnect('cities/post/delete', postData, callback)
	}

    useEffect(() => {
        GetConnect('cities/get', setCities)
    }, [])

	useEffect(()=> {
		setHeaderText(t('ps') + ' / ' + t('city'))
	}, [i18n.language])

    return(
        <>
			<div className=" flex justify-end mb-3 mr-5">
				<Button variant="contained" onClick={() => createModal('citymodal')}>{t('citymodalbutton')}</Button>
			</div>
            <Table
				searchable={true}
				head={[
					{name: '#ID', sortable: true},
					{name: t('city'), sortable: true},
					{name: t('operations'), width: 200},
				]}
				body={cities.length > 0 && cities.map((city) => ([
					<div key={city.id}>#{city.id}</div>,
					<div searchableText={city.name}>{city.name}</div>,
					[
						<button className="h-8 px-4 flex items-center justify-center rounded bg-blue-600 text-white" onClick={() => createModal('citymodal', {data: city.id, callback: setCities})}>{t('edit')}</button>,
						<button onClick={() => Delete(city.id)} className="h-8 px-4 flex items-center justify-center rounded bg-red-600 text-white">{t('delete')}</button>
					]
				]))}
			/>
        </>
    )
}