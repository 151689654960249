import { create } from "zustand";
import { Home, Settings, AppSettings, Feedback, Msi, ListStaff, Scheduled, Inprogress, Completed, Incomplete, FaultList, WarehouseManage, Stock, Reports } from "../Pages"
import { useLoaderStore } from './loader'
const loaderAction = useLoaderStore.getState().loaderAction

export const usePageStore = create((set) => ({
    showPage: <Home />,
    pageText: 'home',
    setPage: page => {
        loaderAction(true)
        switch(page){
            case 'home':
                set({showPage:<Home />})
                set({pageText: page})
                break;
            case 'settings':
                set({showPage:<Settings />})
                set({pageText: page})
                break;
            case 'appsettings':
                set({showPage:<AppSettings />})
                set({pageText: page})
                break;
            case 'feedback':
                set({showPage:<Feedback />})
                set({pageText: page})
                break;
            case 'msi':
                set({showPage:<Msi />})
                set({pageText: page})
                break;
            case 'liststaff':
                set({showPage:<ListStaff />})
                set({pageText: page})
                break;
            case 'scheduled':
                set({showPage:<Scheduled />})
                set({pageText: page})
                break;
            case 'inprogress':
                set({showPage:<Inprogress />})
                set({pageText: page})
                break;
            case 'completed':
                set({showPage:<Completed />})
                set({pageText: page})
                break;
            case 'incomplete':
                set({showPage:<Incomplete />})
                set({pageText: page})
                break;
            case 'reports':
                set({showPage:<Reports />})
                set({pageText: page})
                break;
            case 'faultlist':
                set({showPage:<FaultList />})
                set({pageText: page})
                break;
            case 'warehousemanage':
                set({showPage:<WarehouseManage />})
                set({pageText: page})
                break;
            case 'stock':
                set({showPage:<Stock />})
                set({pageText: page})
                break;
            default:
                set({showPage:<Home />})
                set({pageText: 'home'})
                break
        }
        loaderAction(false)
    }
}))