import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useState } from 'react';
import { useAuthStore } from '../../../Store';
export default function Resetpassword({user, setUser}){

    const changePhone = (e) => {
        let text = e.target.value.replace(" ","");
        text = text.replace(/[^0-9.+]/g,'');
        setUser(text.trim())
    }

    const keyDownHandler = event => {
        if (event.key === 'Enter') {
        event.preventDefault();
        // 👇️ your logic here
        
        }
    };

    return(
        <>
            <TextField 
                id="user" 
                label="Kullanıcı Telefon Numarası" 
                variant="filled" 
                size="small" 
                fullWidth 
                value={user} 
                onChange={changePhone} 
                onKeyDown={keyDownHandler}/>
            <div className='flex-1 my-5'>
                <Button variant="contained">Güvenlik Kodu Gönder</Button>
            </div>
        </>
    )
}