import { useEffect, useState } from "react"
import { useReportsStore } from "../../../Store"
import classNames from "classnames"
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Tooltip } from "@mui/material"

export default function CentralNav({cb}){
    const userCentral = useReportsStore(s=>s.userCentral)
    const [select, setSelect] = useState(null)

    useEffect(() => {
        cb(select)
    }, [select])

    useEffect(() => {
        if(userCentral.length === 1){
            setSelect(userCentral[0]?.central_id)
        }
    }, [userCentral])
    return(
        <>
        {userCentral.length > 1 && <div className=" flex-1 transition-all block shadow-card mb-3 py-2 px-1 relative border border-black/10 bg-black/5 rounded overflow-y-auto">
            <div className=" flex gap-1">
                {select && <Tooltip title='Seçimi Kaldır' placement="right"><div 
                        key="remove"
                        className="shrink-0 p-2 rounded bg-gray-200 cursor-pointer hover:shadow transition-all"
                        onClick={() => setSelect(null)}
                        >
                        <span className=" font-semibold"><HighlightOffIcon  className=" text-red-700" /></span>
                    </div></Tooltip>}
                {userCentral.map(val => (
                    <div 
                        key={val.central_id} 
                        className={classNames("shrink-0 p-2 rounded bg-gray-200 transition-all", {
                                   'bg-gray-100 shadow-inner': val.central_id === select,
                                   'cursor-pointer hover:shadow': val.central_id !== select,
                                   'opacity-50': select && val.central_id !== select,
                        })}
                        onClick={() => setSelect(val.central_id)}
                        >
                        <span className=" font-semibold">{val.central}</span>
                    </div>
                ))}
            </div>
        </div>}
    
    </>
        
    )
}