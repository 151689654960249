import { create } from 'zustand'
import { GetConnect, PostConnect } from '../Core/Connect'
import { useAuthStore, useLoaderStore, useModalStore } from './'
import { Notifation } from '../Utils/Notification'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import PendingIcon from '@mui/icons-material/Pending';
import NextPlanIcon from '@mui/icons-material/NextPlan';
import { Tooltip } from "@mui/material"
import confirm from '../Utils/confirm';

const loaderAction = useLoaderStore.getState().loaderAction
const destroyAllModal = useModalStore.getState().destroyAllModal
const postURL = 'maintenance/post/'
const getURL = 'maintenance/get/'

const pages = ['today', 'inprogress', 'incomplete', 'completed', 'scheduled']


export const useMaintanancesStore = create((set, get) => ({
    maintanances: [],
    maintanance: {},
    page: '',
    status: {
        '1': {element:<Tooltip title='Planlandı' placement="left"><NextPlanIcon fontSize='large' className=' text-gray-600' /></Tooltip>, text:'Planlandı'},
        '2': {element:<Tooltip title='Devam Ediyor' placement="left"><PendingIcon fontSize='large' className=' text-blue-600' /></Tooltip>, text:'Devam Ediyor'},
        '3': {element:<Tooltip title='Tamamlandı' placement="left"><CheckCircleIcon fontSize='large' className=' text-green-600' /></Tooltip>, text:'Tamamlandı'},
        '4': {element:<Tooltip title='İptal Edildi' placement="left"><UnpublishedIcon fontSize='large' className=' text-red-700' /></Tooltip>, text:'İptal Edildi'}
    },
    
    del: (page, data) => {
        set({page:page})
        confirm('Bakımı silmek istediğinize eminmisiniz ?')
        .then(() => {
            Notifation('Bakım planı işlemi', 'Kayıt Silindi', 'success', 2000)
            PostConnect(`${postURL}${page}/delete`, {id:data}, get().callback, useAuthStore.getState().user.data.token)
        })
        .catch(() => {
            Notifation('Bakım planı işlemi', 'Kayıt Silinmedi', 'success', 2000)
        })
        
    },
    inprogress: (page, data) => {
        set({page:page})
        PostConnect(`${postURL}${page}/inprogress`, {id:data}, get().callback, useAuthStore.getState().user.data.token)
    },
    incomplete: (page, data) => {
        set({page:page})
        PostConnect(`${postURL}${page}/incomplete`, {id:data}, get().callback, useAuthStore.getState().user.data.token)
    },
    completed: (page, data) => {
        set({page:page})
        PostConnect(`${postURL}${page}/completed`, {id:data}, get().callback, useAuthStore.getState().user.data.token)
    },
    getdata: page => {
        loaderAction(true)
        GetConnect(`${getURL}${page}`, data => {
            set({maintanances: data})
            loaderAction(false)
        }, useAuthStore.getState().user.data.token)
    },
    getonadata: data => {
        GetConnect(`${getURL}${data}`, data => {
            set({maintanance: data})
        }, useAuthStore.getState().user.data.token)
    },
    record: (page, data) => {
        set({page:page})
        PostConnect(`${postURL}${(data.id  ? 'update' : 'add')}`, data, get().callback, useAuthStore.getState().user.data.token)
        set({maintanance:{}})
    },
    work: (page, data) => {
        set({page:page})
        PostConnect(`${postURL}work`, data, get().callback, useAuthStore.getState().user.data.token)
    },
    callback: data => {        
        Notifation('Bakım planı işlemi', data.data, data.color, 2000)
        if(data.status){
            const target = () => {
                if(pages.includes(get().page)){
                    return get().page
                }else{
                    return 'today'
                }
            }
            get().getdata(target())
            set({maintanance:{}})
            set({page:''})
            destroyAllModal()
        }
        
    }

}))

export const useReportsStore = create((set,get) => ({
    weekly: [],
    monthly: [],
    for_3_months: [],
    for_6_months: [],
    yearly: [],
    for_3_years: [],
    for_10_years: [],
    userCentral: [],
    cares: [],
    report: [],
    getReport: data => {
        GetConnect(`exporter/work/${data}`, (e) => {
            console.log('store', e)
            set({report: e})
        }, useAuthStore.getState().user.data.token)
    },
    getWorks: () => {
        loaderAction(true)
        set({weekly:[], monthly:[], for_3_months:[], for_6_months:[], yearly:[], for_3_years:[], for_10_years:[]})
        GetConnect(`maintenance/get`, get().setWorks, useAuthStore.getState().user.data.token)
        GetConnect(`users/get/${useAuthStore.getState().user.data.id}`, get().setUserCentral, useAuthStore.getState().user.data.token)
    },
    setUserCentral: data => {
        set({userCentral:data[0].centrals})
        //console.log(data[0])
        get().getCares()
    },
    getCares: () => {
        GetConnect(`cares/get/`, get().setCares, useAuthStore.getState().user.data.token)
    },
    setCares: data => {
        let newArray = []
        //console.log(data)
        get().userCentral.map(val => {
            newArray.push({central:val.central_id, name:val.central, cares: data.filter(item => item.central_id === val.central_id)})
        })
        set({cares: newArray})
        
    },
    setWorks: async data => {
        //console.log(data)
        data.map(d => {
            switch(d.carepariod){
                case 2: 
                    set(state => ({weekly: [...state.weekly, d]}))
                break;
                case 3:
                    set(state => ({monthly: [...state.monthly, d]}))
                break;
                case 10:
                    set(state => ({for_3_months: [...state.for_3_months, d]}))
                break;
                case 6:
                    set(state => ({for_6_months: [...state.for_6_months, d]}))
                break;
                case 7:
                    set(state => ({yearly: [...state.yearly, d]}))
                break;
                case 8:
                    set(state => ({for_3_years: [...state.for_3_years, d]}))
                break;
                case 9:
                    set(state => ({for_10_years: [...state.for_10_years, d]}))
                break;
                default:
                    console.log('Değer Eşleşmiyor')
                    break;
            }
        })
        
        loaderAction(false)
    }

}))