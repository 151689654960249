import { TextField, Button } from "@mui/material"
import { useEffect } from "react";
import { useState } from "react";
import { GetConnect, PostConnect } from "../../Core/Connect";
import { useModalStore } from "../../Store";
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

export default function DepartmentModal({data= null}){

    const [department, setDepartment] = useState('')

    const destroyAllModal = useModalStore(s=>s.destroyAllModal)
    
    const callback = (d) => {

        if(d.status === true){
            data.callback(d.data)
            destroyAllModal()
            
        }else{
            alert('Kayıt Tamamlanamadı')
        }
    }

    const Record = () => {
        let postData = {
          id: (data.data ? data.data : null),
          name:department
        }
        PostConnect(`departmans/post/${(data ? 'update' : 'add')}`, postData, callback)
        
    }

    useEffect(() => {

        if(data.data){

            const DATA = (data) => {
                setDepartment(data[0]?.name)
  
            }

            GetConnect(`departmans/get/${data.data}`, DATA)
        }
    },[])

    return(
        <>
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Departman Ekle
            </DialogTitle>
            <DialogContent dividers>
                <TextField id="department" label="Departman Adı" variant="filled" size="small" fullWidth value={department} onChange={e =>setDepartment(e.target.value)} />
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={Record}>
                Gönder
                </Button>
            </DialogActions>
        </>
    )
}