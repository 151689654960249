import { create } from "zustand"
import { useAuthStore, useLoaderStore  } from "../"
import { GetConnect } from "../../Core/Connect"

const loaderAction = useLoaderStore.getState().loaderAction

export const useUserStore = create((set, get) => ({
    users: [],
    getUsers: () => {
        loaderAction(true)
        GetConnect('users/get', get().setUsers, useAuthStore.getState().user.data.token)
    },
    setUsers: data => {
        set({users:data})
        loaderAction(false)
    }
}))