import {useState} from "react";
import SortIcon from '@mui/icons-material/Sort';
import TextRotationDownIcon from '@mui/icons-material/TextRotationDown';
import TextRotateUpIcon from '@mui/icons-material/TextRotateUp';
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { Tooltip } from "@mui/material"
import classNames from "classnames";

export default function Table({head, body, searchable, action=null}) {

	const [sorting, setSorting] = useState(false)
	const [search, setSearch] = useState('')
	const [currentPage, setCurrentPage] = useState(1)
	const filteredData = body && body.filter(
		items => items.some(
			item => (item?.key || item?.props?.searchableText || item).toString().toLocaleLowerCase('TR').includes(search.toString().toLocaleLowerCase('TR'))
		)
	).sort((a, b) => {
		if (sorting?.orderBy === 'asc') {
			return (a[sorting.key]?.key || a[sorting.key]?.props?.searchableText || a[sorting.key]).toString().localeCompare(b[sorting.key]?.key || b[sorting.key]?.props?.searchableText || b[sorting.key])
		}
		if (sorting?.orderBy === 'desc') {
			return b[sorting.key].toString().localeCompare(a[sorting.key])
		}
	})

	const PER_PAGE = 15;
	const offset = (currentPage-1) * PER_PAGE;
	const pageCount = Math.ceil(body?.length / PER_PAGE);
	

	if (!body || body?.length === 0) {
		return (
			<div className="p-4 rounded bg-yellow-100 text-yellow-700 text-sm">Gösterilecek veri bulunmuyor.</div>
		)
	}

	return (
		<>
			{searchable && (
				<div className="mb-4 flex gap-x-2">
					<input
						value={search}
						onChange={e => setSearch(e.target.value)}
						type="text"
						placeholder="Tabloda ara"
						className="h-10 outline-none focus:border-black border rounded text-sm px-4 w-full border-gray-300"
					/>
					{sorting && (
						<button
							onClick={() => setSorting(false)}
							className="h-10 rounded whitespace-nowrap border border-red-500 text-red-500 text-sm px-4">
							Sıralamayı İptal Et
						</button>
					)}
				</div>
			)}
			<div className=" max-w-full overflow-auto border rounded p4">
				<table className="w-full">
					<thead>
					<tr>
						{head.map((h, key) => (
							<th
								width={h?.width}
								className="text-left bg-gray-50 text-sm font-semibold text-gray-500 p-3 border-b"
								key={key}>
								<div className="inline-flex items-center gap-x-2">
									{h.name}
									{h.sortable && (
										<button onClick={() => {
											if (sorting?.key === key) {
												setSorting({
													key,
													orderBy: sorting.orderBy === 'asc' ? 'desc' : 'asc'
												})
											} else {
												setSorting({
													key,
													orderBy: 'asc'
												})
											}
										}}>
											{sorting?.key === key && (
												sorting.orderBy === 'asc' ? <TextRotationDownIcon size={14}/> : <TextRotateUpIcon size={14}/>
											)}
											{sorting?.key !== key && <SortIcon size={14}/>}
										</button>
									)}
								</div>
							</th>
						))}
					</tr>
					</thead>
					<tbody>
					{filteredData.slice(offset, offset+PER_PAGE).map((items, key) => (
						<>
						<tr className={classNames("group", {"border-b border-gray-500": !action})} key={key+offset}>
							{items.map((item, key_) => (
								<td
									className="p-3 text-sm group-hover:bg-blue-50 group-hover:text-blue-600"
									key={key_}>
									{Array.isArray(item) ? (
										<div className="flex gap-x-2.5">
											{item}
										</div>
									) : item}
								</td>
							))}
						</tr>
						{action && action[key+offset] && <tr>
							<td key={`action-${key+offset}`} colSpan={items.length}>
							<Box sx={{ width: '100%'}}>
								<Tooltip title={`${action[key+offset]}% Tamamlandı`} placement="top">
									<LinearProgress variant="buffer" value={action[key+offset]} valueBuffer={parseInt(action[key+offset]) + 2} />
								</Tooltip>
							</Box>
							</td>
						</tr>}
						</>
					))}
					</tbody>
				</table>

				{pageCount > 1 && <div key="pagination" className="flex justify-center items-center my-2 pt-2 border-t border-gray-400">
					<Stack spacing={1}>
						<Pagination count={pageCount} onChange={(e, p) => setCurrentPage(p)}/>
					</Stack>
				</div>}
			</div>
		</>
	)
}