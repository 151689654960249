import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import DialogContent from '@mui/material/DialogContent';

export default function ShowDocs({data}){


    const doc = [
        { uri: "https://api.energoproapp.com"+data } // Local File
      ]

    return (
        <DialogContent dividers>
            <DocViewer 
                documents={doc} 
                config={{
                    header: {
                      disableHeader: true
                    }
                }
            }
                pluginRenderers={DocViewerRenderers} 
                style={{ width:'100%', minWidth:550, height:680}}
                />
        </DialogContent>
    )
    
}

//https://view.officeapps.live.com/op/embed.aspx?src=https%3A%2F%2Fenergoproapp.com%2Fpublic%2Fdoc%2FExporting%2FALP2-BKM-TUR-HFT-013-2023-12-08_16-02-06.xlsx
//https://view.officeapps.live.com/op/view.aspx?src=https%3A%2F%2Fenergoproapp%2Ecom%3A443%2Fpublic%2Fdoc%2FCares%2Falp2%2Du4%2Dturbin%2Dhaftalik%2Dbakim%2Exlsx