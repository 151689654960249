import { TextField, Button, InputLabel, MenuItem, FormControl} from "@mui/material"
import Select from '@mui/material/Select';
import { useState, useEffect } from "react";
import { PostConnect } from "../../Core/Connect";
import { useAuthStore, useModalStore } from "../../Store";

export default function AddStock({data = null}){

    const user = useAuthStore(s=>s.user)
    const [item, setItem] = useState()
    const [stock, setStock] = useState()
    const [addStock, setAddStock] = useState()
    const destroyAllModal = useModalStore(s=>s.destroyAllModal)


    const callback = (d) => {
        
        if(d.status){
            data.c(d.data)
            destroyAllModal()  
        }else{
            alert('Kayıt Tamamlanamadı')
        }
    }

    const Record = () => {

        let postData = {
            id:item,
            stock: addStock
          }
          PostConnect(`warehouse/post/addstock`, postData, callback, user.data.token)
    }

    useEffect(() => {
        if(item !== undefined){
            data.data.map(d => d.id === item && setStock(d.stock))
        }

    }, [item])

    return(
        <div className="flex flex-col">
            <header className=" font-semibold border-b w-full text-left mb-2">Stok Ekle</header>
            <div className="w-full m-auto flex flex-col gap-3">

                <FormControl variant="filled">
                    <InputLabel id="department-label">Lütfen Stock Eklenecek Ürünü Seçiniz.</InputLabel>
                    <Select
                    labelId="department-label"
                    id="department-select"
                    value={item}
                    onChange={e => setItem(e.target.value)}
                    >
                    <MenuItem value="">
                        <em>Seçiniz..</em>
                    </MenuItem>
                    {data.data.map(p => (<MenuItem value={p.id} key={p.id}>
                                            <div className="flex flex-col">
                                                <div className=" font-semibold">{p.name}</div>
                                            </div></MenuItem>))}
                    </Select>
                </FormControl>
                <div className="flex gap-3">
                <TextField id="stock" type="number" variant="filled" size="small" fullWidth value={stock} onChange={e =>setStock(e.target.value)} disabled/>
                <TextField id="stock" type="number" label="Eklenecek Miktar" variant="filled" size="small" fullWidth value={addStock} onChange={e =>setAddStock(e.target.value)} disabled={stock === undefined && 'true'}/>
                </div>
                <Button variant="contained" onClick={() => Record()}>Kayıt Et</Button>
            </div>
        </div>
    )
}