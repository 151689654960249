import { create } from "zustand"
import { useLoaderStore, useAuthStore, useModalStore } from "../"
import { GetConnect, PostConnect } from "../../Core/Connect"
import { Notifation } from "../../Utils/Notification"
import confirm from "../../Utils/confirm"
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingIcon from '@mui/icons-material/Pending';
import { Tooltip } from "@mui/material"

const loaderAction = useLoaderStore.getState().loaderAction
const destroyAllModal = useModalStore.getState().destroyAllModal

export const useFaultStore = create((set,get) => ({
    faults: [],
    fault: [],
    status: {
        '1': {element:<Tooltip title='Açık' placement="left"><PendingIcon fontSize='large' className=' text-gray-600' /></Tooltip>, text:'Açık'},
        '3': {element:<Tooltip title='Tamamlandı' placement="left"><CheckCircleIcon fontSize='large' className=' text-green-600' /></Tooltip>, text:'Tamamlandı'},
    },
    getFaults: () => {
        loaderAction(true)
        GetConnect('faults/get', get().setFaults, useAuthStore.getState().user.data.token)
    },
    setFaults: data => {
        set({faults:data})
        loaderAction(false)
    },
    getFault: data => {
        GetConnect(`faults/get/${data}`, data => {
            set({fault: data})
        }, useAuthStore.getState().user.data.token)
    },
    addFault: data => {
        loaderAction(true)
        PostConnect('faults/post/add', data, get().callback, useAuthStore.getState().user.data.token)
    },
    updateFault: data => { 
        loaderAction(true)
        PostConnect('faults/post/update', data, get().callback, useAuthStore.getState().user.data.token)
    },
    completedFault: data =>{
        loaderAction(true)
        PostConnect('faults/post/completed', {id:data}, get().callback, useAuthStore.getState().user.data.token)
    },
    delFaults: data => {
        confirm('Arıza bildirimini silmek istediğinize eminmisiniz ?')
        .then(() => {
            Notifation('Arıza Kaydı İşlemleri', 'Kayıt Silindi', 'success', 2000)
            PostConnect('faults/post/delete', {id:data}, get().callback, useAuthStore.getState().user.data.token)
        })
        .catch(() => {
            Notifation('Arıza Kaydı İşlemleri', 'Kayıt Silinmedi', 'success', 2000)
        })
        
    },
    callback: data => {
        Notifation('Arıza Kaydı İşlemleri', data.data, data.color, 2000)
        if(data.status){
            get().getFaults()
            destroyAllModal()
        }
    }

}))