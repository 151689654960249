import { create } from "zustand";

export const useModalStore = create((set, get) => ({
    modals: [],
    createModal: (name, data= false) => {
        set(state => ({modals: [...state.modals, {name, data}]}))
    },
    destroyModal: () => {
        const data = [...get().modals]
        data.pop()
        set({modals: data})
    },
    destroyAllModal: () => set(() => ({modals:[]}))
}))