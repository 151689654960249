import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useTranslation } from "react-i18next";
import { useModalStore } from '../Store';

export default function Footer() {


    const { t, i18n } = useTranslation()
    const createModal = useModalStore(s=>s.createModal)

    const changeLang = e => {
        i18n.changeLanguage(e.target.value)
    }

    return(
        <footer className=' -mx-6 border-t border-gray-800/5 h-8 text-sm font-medium flex items-center px-2 bg-gray-300/5 justify-between shadow-[0px_-2px_4px_-1px_rgba(0,0,0,0.2),_0px_-4px_5px_0px_rgba(0,0,0,0.14),_0px_-1px_10px_0px_rgba(0,0,0,0.12)]'>
            <span className=" text-xs">© powered by Energo-Pro</span>
            <div className=' flex justify-center items-center gap-1'>
                <div className=' cursor-pointer' onClick={() => createModal('feedback')}>{t('feedback')}</div>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} size="small">
                    <Select
                        defaultValue={i18n.language}
                        id="lang"
                        value={i18n.language}
                        onChange={changeLang}
                        label="Dil" 
                    >
                        <MenuItem value="tr">Türkçe</MenuItem>
                        <MenuItem value="en">English</MenuItem>
                    </Select>
                </FormControl>
            </div>
            
            
        </footer>
    )
}