import { TextField, Button, InputLabel, MenuItem, FormControl } from "@mui/material"
import Select from '@mui/material/Select';
import { useEffect } from "react";
import { useState } from "react";
import { GetConnect, PostConnect } from "../../Core/Connect";
import { useModalStore, useUnitStore } from "../../Store";
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

export default function UnitModal({data= null}){

    const [unit, setUnit] = useState('')
    const [central, setCentral] = useState('')
    const [centrals, setCentrals] = useState('')
    const getUnit = useUnitStore(s=>s.getUnit)

    const destroyAllModal = useModalStore(s=>s.destroyAllModal)

    const callback = () => {
        getUnit()
        destroyAllModal()
    }

    const Record = () => {
        let postData = {
          id: (data.data ? data.data : null),
          name:unit,
          central:central
        }
        PostConnect(`units/post/${(data ? 'update' : 'add')}`, postData, callback)
        
    }

    useEffect(() => {
        if(data?.data){

            const DATA = (data) => {
                setUnit(data[0]?.name)
                setCentral(data[0]?.central)
  
            }

            GetConnect(`units/get/${data.data}`, DATA)
        }
        GetConnect('centrals/get', setCentrals)
    }, [])

    return(
        <>
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Santral Ekle
            </DialogTitle>
            <DialogContent dividers>
                <TextField id="unit" label="Birim Adı" variant="filled" size="small" fullWidth value={unit} onChange={e =>setUnit(e.target.value)} />
            </DialogContent>
            <DialogContent dividers>
                <FormControl variant="filled" className=" w-full">
                        <InputLabel id="department-label">Santralin Bulunduğu Şehir</InputLabel>
                        <Select
                        labelId="department-label"
                        id="department-select"
                        value={centrals.length > 0 && central}
                        onChange={e => setCentral(e.target.value)}
                        size="small"
                        >
                        <MenuItem value="">
                            <em>Seçiniz..</em>
                        </MenuItem>
                        {centrals.length > 0 && centrals.map(c => (<MenuItem value={c.id} key={c.id}>
                                                <div className="flex flex-col">
                                                    <div className=" font-semibold">{c.name}</div>
                                                    <div className=" font-light text-sm">{c.cityname}</div>
                                                </div></MenuItem>))}
                        </Select>
                    </FormControl>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={Record}>
                Gönder
                </Button>
            </DialogActions>
        </>
    )
}