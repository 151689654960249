import { useEffect, useState } from "react"
import { useReportsStore } from "../../../Store"
import Chart from "./Chart";
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import required modules
import { Pagination } from 'swiper/modules';


import CentralNav from "./CentralNav";
import Calendar from "./Calendar";
import ChartCalendar from "./ChartCalendar";
import moment from "moment";

export default function Reports(){
    const {getWorks} = useReportsStore()
    const weekly = useReportsStore(s=>s.weekly)
    const monthly = useReportsStore(s=>s.monthly)
    const for_3_months = useReportsStore(s=>s.for_3_months)
    const for_6_months = useReportsStore(s=>s.for_6_months)
    const yearly = useReportsStore(s=>s.yearly)
    const for_3_years = useReportsStore(s=>s.for_3_years)
    const for_10_years = useReportsStore(s=>s.for_10_years)

    const [central, setCentral] = useState(null)
    const [weekFilter, setWeekFilter] = useState(1)
    const [monthFilter, setMonthFilter] = useState(1)
    const [quarterFilter, setQuarterFilter] = useState(1)
    const [semesterFilter, setSemesterFilter] = useState(1)
    const [yearFilter, setYearFilter] = useState(moment().year())

    const semerter = (e) => {
      if(e === 1)
        return [moment().year(yearFilter).quarter(1).startOf('quarter'), moment().year(yearFilter).quarter(2).endOf('quarter')]
      else if (e===2)
        return [moment().year(yearFilter).quarter(3).startOf('quarter'), moment().year(yearFilter).quarter(4).endOf('quarter')]
    }
  
    useEffect(() => {
        getWorks()
    }, [])

    useEffect(() => {
      if(semesterFilter)
      console.log(semerter(semesterFilter))
    }, [semesterFilter])

    
    return(
        <section>
          <div className=" flex items-center gap-3 w-full">
          <Calendar selectYear={(e) => setYearFilter(e)} />
          <CentralNav cb={setCentral} />
          
          </div>
          
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            pagination={{
              clickable: true, 
            }}
            breakpoints={{
              400: {
                slidesPerView: 2,
                spaceBetween: 20,
              },800: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              1200: {
                slidesPerView: 4,
                spaceBetween: 40,
              }
            }}
            modules={[Pagination]}
            className="mySwiper z-10"
            noSwipingClass="swiper-no-swiping"
            onClick={e=>console.log(e)}
          >
            <SwiperSlide>
              <ChartCalendar type={2} cb={e => setWeekFilter(e)} currentYear={yearFilter} />
              {weekly.length > 0 && weekFilter && 
                <Chart 
                  text="Haftalık" 
                  type={2} 
                  array={weekly.filter(val => central ? val?.central === central && val : val)
                               .filter(val => moment(val.target_date).isBetween(moment().year(yearFilter).isoWeek(weekFilter).day(0), moment().year(yearFilter).isoWeek(weekFilter).day(6)))} 
                  central={central} 
                />}
            </SwiperSlide>
            <SwiperSlide>
              <ChartCalendar type={3} cb={e => setMonthFilter(e)} currentYear={yearFilter} />
              {monthly.length > 0 && typeof(monthFilter) !== 'undefined' && 
                <Chart 
                  text="Aylık" 
                  type={3} 
                  array={monthly.filter(val => central ? val?.central === central && val : val)
                                .filter(val => moment(val.target_date).isBetween(moment().year(yearFilter).month(monthFilter).date(0), moment().year(yearFilter).month(monthFilter+1).date(0)))}
                  central={central} />}
            </SwiperSlide>
            <SwiperSlide>
              <ChartCalendar type={10} cb={e => setQuarterFilter(e)} currentYear={yearFilter} />
              {(for_3_months.length > 0 && typeof(quarterFilter) !== 'undefined') ? 
                <Chart 
                  text="3 Aylık" 
                  type={10} 
                  array={for_3_months.filter(val => central ? val?.central === central && val : val)
                    .filter(val => moment(val.target_date).isBetween(moment().year(yearFilter).quarter(quarterFilter).startOf('quarter'), moment().year(yearFilter).quarter(quarterFilter).endOf('quarter')))} 
                  central={central} /> : <div className=" w-full py-3 px-2 bg-yellow-100/50 border border-yellow-400 rounded-md text-yellow-800">3 Aylık Veri Bulunamadı</div>}
            </SwiperSlide>
            <SwiperSlide>
            <ChartCalendar type={6} cb={e => setSemesterFilter(e)} currentYear={yearFilter} />
              {for_6_months.length > 0 && typeof(semesterFilter) !== 'undefined' ? 
                <Chart 
                  text="6 Aylık" 
                  type={6} 
                  array={for_6_months.filter(val => central ? val?.central === central && val : val)
                    .filter(val => moment(val.target_date).isBetween(semerter(semesterFilter)[0], semerter(semesterFilter)[1]))} 
                  central={central} /> : <div className=" w-full py-3 px-2 bg-yellow-100/50 border border-yellow-400 rounded-md text-yellow-800">6 Aylık Veri Bulunamadı</div>}
            </SwiperSlide>
            <SwiperSlide>
              <div className=" h-10 my-2"></div>
              {yearly.length > 0 ? 
                <Chart 
                  text="Yıllık" 
                  type={7} 
                  array={yearly.filter(val => central ? val?.central === central && val : val)
                    .filter(val => moment(val.target_date).isBetween(moment().year(yearFilter).startOf('year'), moment().year(yearFilter).endOf('year')))} 
                  central={central} /> : <div className=" w-full py-3 px-2 bg-yellow-100/50 border border-yellow-400 rounded-md text-yellow-800">Yıllık Veri Bulunamadı</div>}
            </SwiperSlide>
            <SwiperSlide>
              <div className=" h-10 my-2"></div>
              {for_3_years.length > 0 ? 
                <Chart 
                  text="3 Yıllık" 
                  type={8} 
                  array={for_3_years.filter(val => central ? val?.central === central && val : val)} 
                  central={central} /> : <div className=" w-full py-3 px-2 bg-yellow-100/50 border border-yellow-400 rounded-md text-yellow-800">3 Yıllık Veri Bulunamadı</div>}
            </SwiperSlide>
            <SwiperSlide>
              <div className=" h-10 my-2"></div>
              {for_10_years.length > 0 ? 
                <Chart 
                  text="10 Yıllık" 
                  type={9} 
                  array={for_10_years.filter(val => central ? val?.central === central && val : val)} 
                  central={central} /> : <div className=" w-full py-3 px-2 bg-yellow-100/50 border border-yellow-400 rounded-md text-yellow-800">10 Yıllık Veri Bulunamadı</div>}
            </SwiperSlide>
          </Swiper>
        </section>
    )
}