import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SettingsIcon from '@mui/icons-material/Settings';
import FeedbackIcon from '@mui/icons-material/Feedback';
import LogoutIcon from '@mui/icons-material/Logout';
import { useTranslation } from "react-i18next";
import { useModalStore, usePageStore, useAuthStore } from '../Store';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

export default function Navigaton ({view}) {
    const { t } = useTranslation()
    const user = useAuthStore(state => state.user)
    const logout = useAuthStore(s=>s.logout)
    const setPage = usePageStore(s=>s.setPage) 
    const pageText = usePageStore(s=>s.pageText) 
    const createModal = useModalStore(s=>s.createModal)

    const Authoritys = user.data.authorities

    const submenu = menu => {
        let m = document.querySelector('#'+menu)
        m.classList.toggle('hidden')
    }

    return(
        <nav className=' h-screen flex flex-col bg-gray-300/5 z-20'>
            <div className=' h-16 flex justify-center items-center'> <img draggable="false" src="./src/img/energopro.jpg" alt="Energo-Pro" /> </div>
            <List sx={{ paddingLeft:2, paddingRight:2}}>
                    <ListItem key='home' disablePadding className=" font-semibold border-b border-gray-400/50 p-2 flex justify-between hover:bg-gray-400/50 hover:rounded cursor-pointer relative">
                        <div id="home" className='block w-full h-full' onClick={() => setPage('home')}>{t('home')}</div>
                    </ListItem>
                    <ListItem key='care' disablePadding 
                        className='relative font-semibold border-b border-gray-400/50 p-2 hover:bg-gray-400/50 hover:rounded flex flex-col' onClick={()=>submenu(`care-${view}`)} >
                            <div className='flex justify-between w-full cursor-pointer'>
                            {t('care')} <KeyboardArrowDownIcon />
                            </div>
                            <div id={`care-${view}`} className=" hidden w-full right-0 mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5" >
                                <div className="py-1" role="none">
                                    {(Authoritys.manageapp || Authoritys.newmaintanence) && <div className="text-gray-700 block px-4 py-2 text-sm cursor-pointer hover:bg-gray-400/50" role="menuitem" id="menu-item-0" onClick={() => createModal('createmaintenance', {page:pageText})}>Yeni Bakım Planla</div>}
                                    <div className="text-gray-700 block px-4 py-2 text-sm cursor-pointer hover:bg-gray-400/50" role="menuitem" id="menu-item-1" onClick={() => setPage('scheduled')}>Planlanan Bakımlar</div>
                                    <div className="text-gray-700 block px-4 py-2 text-sm cursor-pointer hover:bg-gray-400/50" role="menuitem" id="menu-item-2" onClick={() => setPage('inprogress')}>Devam Eden Bakımlar</div>
                                    <div className="text-gray-700 block px-4 py-2 text-sm cursor-pointer hover:bg-gray-400/50" role="menuitem" id="menu-item-3" onClick={() => setPage('completed')}>Tamamlanan Bakımlar</div>
                                    <div className="text-gray-700 block px-4 py-2 text-sm cursor-pointer hover:bg-gray-400/50" role="menuitem" id="menu-item-4" onClick={() => setPage('incomplete')}>İptal Edilen Bakımlar</div>
                                    <div className="text-gray-700 block px-4 py-2 text-sm cursor-pointer hover:bg-gray-400/50" role="menuitem" id="menu-item-4" onClick={() => setPage('reports')}>Raporlar/İstatistikler</div>
                                </div>
                            </div>
                    
                    </ListItem>
                    
                    <ListItem key='fault' disablePadding className=' relative font-semibold border-b border-gray-400/50 p-2 hover:bg-gray-400/50 hover:rounded flex flex-col' onClick={()=>submenu(`fault-${view}`)}>
                        <div className='flex justify-between w-full cursor-pointer'>
                            {t('fault')} <KeyboardArrowDownIcon />
                            </div>
                            <div id={`fault-${view}`} className=" hidden w-full right-0 mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5" >
                                <div className="py-1" role="none">
                                    <div className="text-gray-700 block px-4 py-2 text-sm cursor-pointer hover:bg-gray-400/50" role="menuitem" id="menu-item-0" onClick={() => createModal('createfault')}>Arıza Bildir</div>
                                    <div className="text-gray-700 block px-4 py-2 text-sm cursor-pointer hover:bg-gray-400/50" role="menuitem" id="menu-item-1" onClick={() => setPage('faultlist')}>Bildirilen Arızalar</div>
                                </div>
                            </div>
                    </ListItem>
                    
                    {(Authoritys.manageapp || Authoritys.managestaff) &&<ListItem key='staff' disablePadding className=' relative font-semibold border-b border-gray-400/50 p-2 hover:bg-gray-400/50 hover:rounded flex flex-col' onClick={()=>submenu(`staff-${view}`)}>
                        <div className='flex justify-between w-full cursor-pointer'>
                            {t('staff')} <KeyboardArrowDownIcon />
                        </div>
                        <div id={`staff-${view}`} className=" hidden w-full right-0 mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5" >
                            <div className="py-1" role="none">
                                <div className="text-gray-700 block px-4 py-2 text-sm cursor-pointer hover:bg-gray-400/50" role="menuitem" id="menu-item-0" onClick={() => createModal('createstaff')}>{t('newstaff')}</div>
                                <div className="text-gray-700 block px-4 py-2 text-sm cursor-pointer hover:bg-gray-400/50" role="menuitem" id="menu-item-0" onClick={() => setPage('liststaff')}>{t('liststaff')}</div>
                            </div>
                        </div>
                    </ListItem>}
                    <ListItem key='inventory' disablePadding className=' relative font-semibold border-b border-gray-400/50 p-2 hover:bg-gray-400/50 hover:rounded flex flex-col' onClick={()=>submenu(`inventory-${view}`)}>
                        <div className='flex justify-between w-full cursor-pointer'>
                            {t('inventory')} <KeyboardArrowDownIcon />
                        </div>
                        <div id={`inventory-${view}`} className=" hidden w-full right-0 mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5" >
                            <div className="py-1" role="none">
                                {(Authoritys.manageapp || Authoritys.managewarehouse) && <div className="text-gray-700 block px-4 py-2 text-sm cursor-pointer hover:bg-gray-400/50" role="menuitem" id="menu-item-0" onClick={() => setPage('warehousemanage')}>Depo Yönetimi</div>}
                                <div className="text-gray-700 block px-4 py-2 text-sm cursor-pointer hover:bg-gray-400/50" role="menuitem"  id="menu-item-0" onClick={() => setPage('stock')}>Envanteri Görüntüle</div>
                            </div>
                        </div>
                    </ListItem>
                    <ListItem key='msi' disablePadding className=' font-semibold border-b border-gray-400/50 p-2 flex justify-between hover:bg-gray-400/50 hover:rounded cursor-pointer relative'>
                        <div id="msi" className='block w-full h-full' onClick={() => setPage('msi')}>{t('msi')}</div>
                    </ListItem>
                    {(Authoritys.manageapp) && <ListItem key='appsettings' disablePadding className=' font-semibold border-b border-gray-400/50 p-2 flex justify-between hover:bg-gray-400/50 hover:rounded cursor-pointer relative'> 
                        <div className=' items-center w-full h-full'>
                            <div id="appsettings" className=' block w-full h-full cursor-pointer absolute' onClick={() => setPage('appsettings')}></div>
                            <SettingsIcon fontSize='small' /> {t('ps')}</div>
                    </ListItem>}
                    {(Authoritys.manageapp) && <ListItem key='feedback' disablePadding className=' font-semibold border-b border-gray-400/50 p-2 flex justify-between hover:bg-gray-400/50 hover:rounded cursor-pointer relative'> 
                        <div className=' items-center w-full h-full'>
                            <div id="feedback" className=' block w-full h-full cursor-pointer absolute' onClick={() => setPage('feedback')}></div>
                            <FeedbackIcon fontSize='small' /> {t('feedback')}</div>
                    </ListItem>}
            </List>
            <div className=' flex justify-between  mt-auto items-center border-t border-gray-400/50 pt-2 px-1 pb-1'>
                <div className=' flex flex-col text-sm'> <span className='  font-bold'>{user.data.name}</span> <i>{user.data.departmentname}</i></div>
                <div className='flex gap-2'>
                    <div className=' border border-gray-400/70 p-1 flex items-center justify-center rounded relative hover:bg-gray-400/50 hover:rounded'>
                        <div id="settings" className=' block w-full h-full cursor-pointer absolute' onClick={() => logout()}></div>
                        <LogoutIcon />
                    </div>

                    <div className=' border border-gray-400/70 p-1 flex items-center justify-center rounded relative hover:bg-gray-400/50 hover:rounded'>
                        <div id="settings" className=' block w-full h-full cursor-pointer absolute' onClick={() => setPage('settings')}></div>
                        <SettingsIcon />
                    </div>
                </div>  
            </div>
        </nav>

    )
}