import { useEffect } from "react"
import { Table } from "../../Components"
import Moment from 'moment'
import { useTranslation } from "react-i18next"
import { useAuthStore, useHeaderTextStore, useModalStore, useMaintanancesStore } from "../../Store"
import { ActionButton } from "../../Components/Actionbutton"
import FactCheckIcon from '@mui/icons-material/FactCheck';
import classNames from "classnames"

export default function Scheduled(){

    const { t } = useTranslation()

    const setHeaderText = useHeaderTextStore(s=>s.setHeaderText)
    const createModal = useModalStore(s=>s.createModal)
    const user = useAuthStore(s=>s.user)
    const maintanances = useMaintanancesStore(s=>s.maintanances)
    const { getdata, del, inprogress, incomplete, completed, status, work } = useMaintanancesStore()

    const Authoritys = user.data.authorities

    useEffect(() => {
        setHeaderText('Planlanan Bakım Planları')
        getdata('scheduled')
    }, [])


    return(
        <>
            <div className=" flex items-center">
                <div className="text-sm">
                    <ActionButton data="" modal="refresh" className="border-none text-sm" fnc={() => getdata('scheduled')} /> Listeyi Güncelle
                </div>
            </div>
            <Table
				searchable={true}
				head={[
                    {name: '#', width: 24},
					{name: t('Bakım Bilgileri'), width: 350, sortable: true},
                    {name: t('explanation'), width: 600},
					{name: t('status'), width: 45}
				]}
				body={maintanances && maintanances.map((maintanance) => ([
                    [
                        <div className=" flex flex-col">
                            <ActionButton data={{data:{id:maintanance.id, type:'maintanence'}}} modal='createnotification' />
                            <ActionButton disabled={JSON.parse(maintanance?.works)?.length === JSON.parse(maintanance?.complate_works)?.length ? false:true} data={{data:maintanance.id}} modal='completed' fnc={(m, d) => completed('scheduled', d.data)} />
                            <ActionButton data={{data:maintanance.id}} modal='inprogress' fnc={(m, d) => inprogress('scheduled', d.data)} />
                            <ActionButton data={{data:maintanance.id}} modal='incomplete' fnc={(m, d) => incomplete('scheduled', d.data)} />
                            {(maintanance.status === 3 || maintanance.status === 4) && <ActionButton data={{data:{id:maintanance.id, type:'maintanence'}}} modal='report' />}
                        </div>,
                        ((Authoritys.managemaintanence || Authoritys.manageapp) && <div className=" flex flex-col">
                            <ActionButton data={{data:maintanance.id, page:'scheduled'}} modal='createmaintenance' />
                            <ActionButton data={{data:maintanance.id}} modal='delete' fnc={(m, d) => del('scheduled', d.data)} />
                        </div>)
					],
                    <div searchableText={`${maintanance.id} - ${maintanance.title} ${maintanance.centralname} ${maintanance.unitname}`} className=" flex flex-col whitespace-nowrap text-xs">
                        <span className="border-b border-gray-200 flex justify-between"><strong>{t('title')}:</strong> {maintanance.title ? maintanance.title : maintanance.centralname + ' '  + maintanance.carename}</span>
                        <span className="border-b border-gray-200 flex justify-between"><strong>{t('addeduser')}:</strong> {maintanance.added_username}</span>
                        <span className="border-b border-gray-200 flex justify-between"><strong>{t('relateduser')}:</strong> {maintanance.related_username ? maintanance.related_username : <em>{t('homepage.h7')}</em>}</span>
                        <span className="border-b border-gray-200 flex justify-between"><strong>{t('powerplant')}:</strong> {maintanance.centralname}</span>
                        <span className="border-b border-gray-200 flex justify-between"><strong>{t('unit')}:</strong> {maintanance.unitname}</span>
                        <span className="border-b border-gray-200 flex justify-between"><strong>{t('createdate')}:</strong> {Moment(maintanance.create_date).format('DD MM YYYY')}</span>
                        <span className="border-b border-gray-200 flex justify-between"><strong>{t('planeddate')}:</strong> {(maintanance.target_date ? Moment(maintanance.target_date).format('DD MM YYYY') : '-')}</span>
                        <span className="border-b border-gray-200 flex justify-between"><strong>Tamamlanma Tarihi:</strong> {(maintanance.end_date ? Moment(maintanance.end_date).format('DD MM YYYY') : '-')}</span>
                        {maintanance.carefile && <span className=" bg-black/50 text-white border-b border-gray-200 flex justify-between items-center px-1 py-1.5 rounded"><strong>Örnek Dosya:</strong> <button onClick={() => createModal('showdocs', maintanance.carefile)} className="hover:text-gray-600 hover:bg-gray-200 rounded p-1">{maintanance.carename}</button></span>}
                    </div>,
                    <div searchableText={maintanance.explanation} className=" flex flex-col whitespace-nowrap text-xs">
                        <strong>{maintanance.explanation}</strong>
                        <div className=" flex flex-col gap-1 max-h-40 overflow-x-hidden max-w-[600px] overflow-y-auto pr-1">
                            {maintanance.works && JSON.parse(maintanance.works).map((v, k) => {
                                let control = maintanance?.complate_works && maintanance?.complate_works.includes('c'+k) && true
                                return(
                                    <>
                                    {(maintanance.status === 3 || maintanance.status === 4) && <div className=" cursor-pointer border border-gray-200 p-1 rounded hover:bg-gray-200 text-black group/work" key={k}>
                                        <span className=" block"><FactCheckIcon className={classNames(' group-hover/work:text-green-600',
                                        {'text-green-600': control})} /> <strong>{v.control}</strong></span>
                                        <em className="whitespace-break-spaces">{v.instructions}</em>
                                    </div>}

                                    {(maintanance.status === 1 || maintanance.status === 2) && <div className=" cursor-pointer border border-gray-200 p-1 rounded hover:bg-gray-200 text-black group/work" key={k} onClick={() => work('scheduled', {id:maintanance.id, work:'c'+k})}>
                                        <span className=" block"><FactCheckIcon className={classNames(' group-hover/work:text-green-600',
                                        {'text-green-600': control})} /> <strong>{v.control}</strong></span>
                                        <em className="whitespace-break-spaces">{v.instructions}</em>
                                    </div>}
                                    </>
                                    
                                )})
                            }
                        </div>
                    </div>,
                    <div searchableText={status[maintanance.status]['text']}>{status[maintanance.status]['element']}</div>
					
				]))}
                action={maintanances && maintanances.map((m) => {
                    let totalWork = JSON.parse(m?.works)
                    let complateWork = JSON.parse(m?.complate_works)
                    let progress = Math.floor(complateWork?.length*100/totalWork?.length)
                    return ([progress ? progress : 0]
                    )})}
			/>
        </>
    )
}