import { TextField, Button, InputAdornment } from "@mui/material"
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useEffect, useState } from "react";
import Moment from 'moment'
import { useAuthStore, useModalStore, useNotificationStore } from "../../Store";
import { Table } from "../../Components";
import { useTranslation } from "react-i18next"
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

export default function CreateNotification({data= null}){

    const { t } = useTranslation()
    const user = useAuthStore(s=>s.user)
    const [selectedFile, setSelectedFile] = useState({})
    const [explanation, setExplanation] = useState('')
    const Notifications = useNotificationStore(s=>s.notifications)
    const {getdata, record} = useNotificationStore()

    const createModal = useModalStore(s=>s.createModal)


    const selectChange = (event) => {
		setSelectedFile(event.target.files[0])  
	};

    useEffect(() => {
        getdata({type:data?.data?.type === 'maintanence' ? 'work_id':'fault_id', id:data?.data?.id})
    },[])

    const Record = () => {

        const formData = new FormData();

		formData.append('file', selectedFile);
        fetch('https://api.energoproapp.com/upload.php?key=NNLYbbVHOS0KR6UR1NpnQH13WsRjnDEf&folder=Notification',
			{
				method: 'POST',
				body: formData,
			})
            .then((response) => response.json())
            .then((result) => {

                let postData = {
                    file: result?.link,
                    user_id: user.data.id,
                    work_id: data.data.type === "maintanence" ? data.data.id : null,
                    fault_id: data.data.type === "fault" ? data.data.id : null,
                    explanation: explanation
                }
                record(postData)
                
        })
        .catch(() => {
            alert('Kayıt aşarısız')
        });


    }

    return(

        <>
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Çalışma Notu
            </DialogTitle>
            <DialogContent dividers>
                <TextField 
                            label="Dosya" 
                            type="file" 
                            name="file" 
                            id="file"
                            variant="filled" 
                            size="small" 
                            fullWidth 
                            onChange={selectChange} 
                            InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <CloudUploadIcon />
                                  </InputAdornment>
                                ),
                              }}
                        />
            </DialogContent>
            <DialogContent dividers>
                <TextField id="explanation" multiline rows={4} label="Not" variant="filled" size="small" fullWidth value={explanation} onChange={e =>setExplanation(e.target.value)} />
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={Record}>
                Gönder
                </Button>
            </DialogActions>
            <DialogContent dividers>
                <div className=" max-h-96 overflow-auto">        
                    <Table
                        searchable={true}
                        head={[
                            {name: '#', width: 24,sortable: true},
                            {name: t('Not')},
                            {name: t('date'), width: 200, sortable: true}
                        ]}
                        body={Notifications && Notifications.map((notification) => ([
                            notification.id,
                            <div searchableText='' className=" flex flex-col whitespace-nowrap text-xs">
                                <span><strong>{t('Dosya')}:</strong> {notification.file && <button onClick={() => createModal('showdocs', notification.file)} className="hover:text-gray-600 hover:bg-gray-200 rounded p-2">Dosya</button>}</span>
                                <span><strong>{t('addeduser')}:</strong> {notification.username}</span>
                                <span>{notification.explanation}</span>
                            </div>,
                            <div key={Moment(notification.create_date).format('DD MM YYYY')} className=" flex flex-col whitespace-nowrap text-xs">
                                {Moment(notification.create_date).format('DD MM YYYY')}
                            </div>
                        ]))}
                    />
                    </div>
            </DialogContent>
        </>
    )
}